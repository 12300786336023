import {getProjectOrigin} from "../functions/getProjectOrigin";
import {FormDataCreator} from "./class.FormDataCreator";

export interface IApiCallerResponse<T = any> {
    mimeString: string;
    response: T;
    status: number;
    raw: boolean;
}

export interface IApiCallerError {
    status: number;
}

export class ApiCaller {
    private static formatParams(params: any) {
        if (params) {
            const formDataCreator = new FormDataCreator(params);

            return "?" + formDataCreator.param();
        }
        return "";
    }

    private path = getProjectOrigin("/api/public/v1/index.php");

    public call<T = any>(route: string, method = "GET", options?: any, data?: any): Promise<IApiCallerResponse<T>> {
        const callStart = (new Date()).getTime();
        return new Promise((resolve, reject) => {
            const xhttp = new XMLHttpRequest();
            xhttp.open(method, this.path + "/" + route + ApiCaller.formatParams(options), true);
            let rRaw = true;
            xhttp.addEventListener("load", function() {
                let mimeString = "";
                let response = "";
                const contentType = this.getResponseHeader("Content-Type");
                if (contentType) {
                    mimeString = contentType.split(";")[0];
                    mimeString = mimeString.toLowerCase().trim();
                }

                if (mimeString === "application/json") {
                    response = JSON.parse(this.response);
                    rRaw = false;
                } else {
                    response = this.response;
                }
                const callEnd = (new Date()).getTime();
                console.log(`${method} call to /${route} took ${(callEnd - callStart) / 1000} s`);
                resolve({
                    status: this.status,
                    raw: rRaw,
                    mimeString: mimeString,
                    response: response
                } as IApiCallerResponse);
            });
            xhttp.addEventListener("error", function() {
                reject({status: this.status});
            });
            if (data instanceof FormData) {
                xhttp.send(data);
            } else {
                xhttp.setRequestHeader("Content-Type", "application/json");
                xhttp.send(JSON.stringify(data));
            }
        });
    }
}
