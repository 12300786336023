import * as React from "react";
import * as styles from "./Register.less";
import {getProjectOrigin} from "../../functions/getProjectOrigin";
import {Dialog} from "../Dialog";

export class Register extends React.Component {
    static defaultProps = {
        close: function() {
            console.error("No close handler");
        },
        handleNotification: function() {
            console.error("No notification handler");
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            firma: "",
            name: "",
            telefon: "",
            username: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }

    handleSubmit() {
        const formData = new FormData();
        const props = Object.keys(this.state);
        for (let key of props) {
            formData.append(key, this.state[key]);
        }

        const url = getProjectOrigin("/modules/register/register.php");
        const xhttp = new XMLHttpRequest();
        xhttp.open("POST", url, false);

        xhttp.addEventListener("load", (data) => {
            if (data.error) {
                this.props.handleNotification("Fehler bei der Registrierung!", "error");
                return false;
            } else {
                this.props.handleNotification(
                    "Vielen Dank für Ihre Anmeldung. Sie erhalten in Kürze eine E-Mail mit Ihren Zugangsdaten.",
                    "success"
                );
            }
            this.props.close();
        });
        xhttp.send(formData);
    }

    handleInput(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    render() {
        return (
            <Dialog width={650} height={850} title={"Anmeldung"} close={this.props.close}>
                <div className={styles.signupform}>
                    <div className={styles.introduction}>
                        <h1>Vielen Dank für Ihr Interesse an PHPW5</h1>
                        <p>
                            Bitte füllen Sie das Formular aus und Sie erhalten umgehend eine E-Mail
                            mit Ihren Zugangsdaten.
                        </p>
                    </div>
                    <div className={styles.errorBox}/>
                    <div className={styles.formfields}>
                        <div className={styles.formrow}>
                            <div className={styles.formlabel}>
                                <span>Ihr Name</span>
                                <span className={styles.requiredfield}> *</span>
                            </div>
                            <div className={styles.formfield}>
                                <input
                                    className="mandatory"
                                    value={this.state.name}
                                    type="text"
                                    onChange={this.handleInput}
                                    name="name"
                                    placeholder="z.B. Max Mustermann"
                                />
                            </div>
                        </div>
                        <div className={styles.formrow}>
                            <div className={styles.formlabel}>
                                <span>Ihre Firma</span>
                            </div>
                            <div className={styles.formfield}>
                                <input
                                    value={this.state.firma}
                                    type="text"
                                    onChange={this.handleInput}
                                    name="firma"
                                    placeholder="z.B. Muster GmbH"
                                />
                            </div>
                        </div>
                        <div className={styles.formrow}>
                            <div className={styles.formlabel}>
                                <span>Ihre E-Mail Adresse</span>
                                <span className={styles.requiredfield}> *</span>
                            </div>
                            <div className={styles.formfield}>
                                <input
                                    value={this.state.email}
                                    className="mandatory"
                                    onChange={this.handleInput}
                                    name="email"
                                    type="text"
                                    placeholder="z.B. mustermann@mustergmbh.de"
                                />
                            </div>
                        </div>
                        <div className={styles.formrow}>
                            <div className={styles.formlabel}>
                                <span>Ihre Telefonnummer</span>
                                <span className={styles.requiredfield}> *</span>
                            </div>
                            <div className={styles.formfield}>
                                <input
                                    value={this.state.telefon}
                                    className="mandatory"
                                    onChange={this.handleInput}
                                    name="telefon"
                                    type="text"
                                    placeholder="z.B. 09396 970150"
                                />
                            </div>
                        </div>
                        <div className={styles.formrow}>
                            <div className={styles.formlabel}>
                                <span>Wunsch-Benutzername</span>
                            </div>
                            <div className={styles.formfield}>
                                <input
                                    value={this.state.username}
                                    type="text"
                                    onChange={this.handleInput}
                                    name="username"
                                    placeholder="z.B. mustermax"
                                />
                            </div>
                        </div>
                        <div className={styles.formrow + " " + styles.loginButtons}>
                            <button className={styles.signupBtn} onClick={this.handleSubmit}>
                                Zugang anfragen
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}
