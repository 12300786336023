import {action, computed, observable} from "mobx";
import {observer} from "mobx-react";
import * as React from "react";
import {DraggableData, Position, ResizableDelta} from "react-rnd";
import * as styles from "./Dialog.less";
import {Modal} from "./Modal";
import {RndFixed} from "./RndFixed";
import {StandardButton} from "./Standard/StandardButton";
import {InlineSvg} from "./Svg/InlineSvg";

interface IDialogButton {
    label: string;

    onClick(): void;
}

export interface IProps {
    buttons?: IDialogButton[];
    dialogStyle?: { [s: string]: string };
    height?: number;
    modal?: boolean;
    title: string;
    width?: number;
    minHeight?: number | string;
    minWidth?: number | string;

    close(): void;
}

class DialogStore {
    @observable public x = 200;
    @observable public y = 200;
    @observable public width = 0;
    @observable public height = 0;

    constructor(props: IProps) {
        this.init(props);
    }

    //
    // @action
    // public size = (dimension: { width?: number, height?: number }) {
    //     if (dimension.width !== undefined && isFinite(dimension.width)) {
    //         this.width = dimension.width;
    //     }
    //     if (dimension.height !== undefined && isFinite(dimension.height)) {
    //         this.height = dimension.height;
    //     }
    // }

    @computed
    get size(): { width: number, height: number } {
        return {width: this.width || 0, height: this.height || 0};
    }

    set size(dimesion: { width: number, height: number }) {
        this.width = dimesion.width;
        this.height = dimesion.height;
    }

    @computed
    get position(): { x: number, y: number } {
        return {x: this.x || 0, y: this.y || 0};
    }

    set position(position: { x: number, y: number }) {
        this.x = position.x;
        this.y = position.y;
    }

    @action
    public refitHorizontal() {
        const vpWidth = window.innerWidth;
        let xCorrection = parseFloat(this.x.toString());
        let widthCorrection = parseFloat((this.width || (vpWidth / 4)).toString());

        if (xCorrection + widthCorrection > vpWidth) {
            xCorrection -= (xCorrection + widthCorrection) - vpWidth;
        }
        if (xCorrection < 0) {
            xCorrection = 0;
        }
        if (widthCorrection > vpWidth) {
            widthCorrection = vpWidth;
        }

        this.x = xCorrection;
        this.width = widthCorrection;
    }

    @action
    public refitVertical() {
        const vpHeight = window.innerHeight;
        let yCorrection = parseFloat(this.y.toString());
        let heightCorrection = parseFloat((this.height || (vpHeight / 4)).toString());

        if (yCorrection + heightCorrection > vpHeight) {
            yCorrection -= (yCorrection + heightCorrection) - vpHeight;
        }
        if (yCorrection < 0) {
            yCorrection = 0;
        }
        if (heightCorrection > vpHeight) {
            heightCorrection = vpHeight;
        }

        this.y = yCorrection;
        this.height = heightCorrection;
    }

    @action
    public centerHorizontal() {
        this.refitHorizontal();

        const vpWidth = window.innerWidth;
        const position = this.position;

        position.x = (vpWidth - this.size.width) / 2;

        this.position = position;
    }

    @action
    public centerVertical() {
        this.refitVertical();

        const vpHeight = window.innerHeight;
        const position = this.position;

        position.y = (vpHeight - this.size.height) / 2;

        this.position = position;
    }

    @action
    private init(props: IProps) {
        const vpWidth = window.innerWidth;
        const vpHeight = window.innerHeight;
        this.size = ({
            width: parseFloat((props.width || (vpHeight / 4)).toString()),
            height: parseFloat((props.height || (vpWidth / 4)).toString())
        });
        this.refitHorizontal();
        this.refitVertical();
    }
}

@observer
export class Dialog extends React.Component<IProps> {
    public static defaultProps: Partial<IProps> = {
        buttons: [],
        close: () => {
            console.error("No close handler");
        },
        dialogStyle: {},
        modal: true,
        minWidth: 300,
        minHeight: 100,
        title: "Dialog"
    };

    private dialogStore: DialogStore;

    private dialogInnerRef: React.RefObject<HTMLDivElement> = React.createRef();
    private dialogRef: React.RefObject<RndFixed> = React.createRef();

    constructor(props: IProps) {
        super(props);
        this.dialogStore = new DialogStore(props);
    }

    public componentDidMount() {
        const innerRef = this.dialogInnerRef.current;
        if (innerRef) {
            this.dialogStore.size = ({width: innerRef.offsetWidth, height: innerRef.offsetHeight});
            this.dialogStore.centerHorizontal();
            this.dialogStore.centerVertical();
        }
    }

    public render() {
        let buttons: React.ReactNode[] = [];
        if (this.props.buttons) {
            buttons = this.props.buttons.map((button, index) => (
                <StandardButton key={index} onClick={button.onClick}>
                    {button.label}
                </StandardButton>
            ));
        }
        const size: { width: string | number; height: string | number } = {
            height: "",
            width: ""
        };
        if (this.dialogStore.width) {
            size.width = this.dialogStore.width;
        }
        if (this.dialogStore.height) {
            size.height = this.dialogStore.height;
        }

        const dialog = (
            <RndFixed
                dragHandleClassName={styles.draggable}
                bounds="body"
                size={size}
                position={this.dialogStore.position}
                className={styles.dialog}
                minWidth={this.props.minWidth}
                minHeight={this.props.minHeight}
                onResizeStop={this.handleResizeStop}
                onResize={this.handleResize}
                onDragStop={this.handleDragStop}
                ref={this.dialogRef}
            >
                <div ref={this.dialogInnerRef} className={styles.dialogInner}>
                    <div className={styles.titlebar}>
                    <span className={styles.draggable}>
                        <b>{this.props.title}</b>
                    </span>
                        <button onClick={this.handleClose} className={styles.closeButton}>
                            <div style={{width: "20px", height: "20px"}}>
                                <InlineSvg name="close"/>
                            </div>
                        </button>
                    </div>
                    <div className={styles.content}>
                        {this.props.children}
                    </div>
                    {buttons.length > 0 && (
                        <div className={styles.buttons}>
                            {buttons}
                        </div>
                    )}
                </div>
            </RndFixed>
        );

        if (this.props.modal) {
            return <Modal>{dialog}</Modal>;
        } else {
            return dialog;
        }
    }

    private handleClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        this.props.close();
    }

    private handleResizeStop = (e: MouseEvent | TouchEvent, direction: string, ref: HTMLElement) => {
        this.dialogStore.size = ({width: ref.offsetWidth, height: ref.offsetHeight});
    }

    private handleDragStop = (e: Event, data: DraggableData) => {
        this.dialogStore.position = ({x: data.x, y: data.y});
    }

    private handleResize = (
        e: MouseEvent | TouchEvent,
        direction: string, ref: HTMLElement, delta: ResizableDelta, position: Position) => {
        this.dialogStore.size = ({width: ref.offsetWidth, height: ref.offsetHeight});
        this.dialogStore.position = ({x: position.x, y: position.y});
    }
}
