// tslint:disable-next-line:ban-types
export function debounce(func: Function, wait: number = 500, immediate: boolean = false) {
    let timeout: number | undefined;
    return function() {
        // @ts-ignore
        // tslint:disable-next-line:no-this-assignment
        const context = this;
        const args = arguments;
        const later = function() {
            timeout = undefined;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = window.setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}
