import {observer} from "mobx-react";
import * as React from "react";
import * as styles from "./InlineSvg.less";
import {InlineSvgStore} from "./Store/InlineSvgStore";
import {SvgLoader} from "./SvgLoader";

export type ValidIconName = keyof typeof SvgLoader;

export interface IProps {
    name?: ValidIconName;
    width?: string | number;
    height?: string | number;
    style?: { [s: string]: string };
    title?: string;
}

@observer
export class InlineSvg extends React.Component<IProps> {
    public static defaultProps = {
        height: false,
        width: false
    };

    private inlineSvgStore = InlineSvgStore.create({svg: ""});

    constructor(props: IProps) {
        super(props);
        this.init().then();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>) {
        this.init().then();
    }

    public render() {
        return (
            <div className={styles.inline_wrapper}>
                <div
                    className={styles.inline}
                    style={{
                        height: this.props.height,
                        width: this.props.width
                    }}
                    dangerouslySetInnerHTML={this.inlineSvgStore.markup}
                />
            </div>
        );
    }

    private async init() {
        if (this.props.name) {
            if (SvgLoader.hasOwnProperty(this.props.name)) {
                const svg = await SvgLoader[this.props.name]();
                this.inlineSvgStore.setSvg(svg.default);
            } else {
                console.error("SvgLoader couldn't find " + this.props.name);
            }
        } else {
            const svg = await SvgLoader.alien();
            this.inlineSvgStore.setSvg(svg.default);
            console.error("SvgLoader: No Name, no Loader");
        }
    }
}
