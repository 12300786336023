import * as React from "react";
import {StandardFormattingInput} from "./StandardFormattingInput";
import * as styles from "./StandardInput.less";
import {StandardCheckbox} from "./StandardCheckbox";
import {observer} from "mobx-react";

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    autoComplete?: string;
    autoFocus?: boolean;
    autoSelect?: boolean;
    ref?: React.Ref<HTMLInputElement>;
    className?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    type?: string;
    suffix?: string;
    value: string;
    options?: { [key: string]: string };

    onEnter?(event: React.KeyboardEvent<HTMLInputElement>): void;
}

export const StandardInput =(function StandardInput(
    props: {
        value: string,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
        onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void,
        disabled?: boolean,
        style?: React.CSSProperties
    }
) {
    const value = () => {
        return props.value;
    };
    return (
        <TextField
            {...props}
            value={value()}
        />
    );
})

@observer
export class TextField extends React.Component<IProps> {
    public static defaultProps: Partial<IProps> = {
        autoComplete: "off",
        autoFocus: false,
        autoSelect: false,
        className: "",
        disabled: false,
        onBlur: undefined,
        onChange: () => {
            console.error("No change handler");
        },
        onKeyPress: undefined,
        suffix: "",
        type: "text"
    };
    private readonly textInput: React.RefObject<HTMLInputElement>;

    constructor(props: IProps) {
        super(props);
        this.textInput = React.createRef();
    }

    public componentDidMount() {
        if (this.props.autoFocus && this.textInput.current) {
            this.textInput.current.autofocus = true;
        }
    }

    public componentDidUpdate() {
        this.componentDidMount();
    }

    public render(): React.ReactNode {
        const propsCopy = Object.assign({}, this.props) as IProps;
        switch (this.props.type) {
            case "euro":
                return <StandardFormattingInput {...this.props} suffix={"€"}/>;
            case "percent":
                return <StandardFormattingInput {...this.props} suffix={"%"}/>;
            case "number":
                return <StandardFormattingInput {...this.props} suffix={""}/>;
            case "checkbox":
                return <StandardCheckbox {...this.props}/>;
            default:
                propsCopy.className = [styles.standardinput, propsCopy.className].join(" ");
                delete propsCopy.autoSelect;
                delete propsCopy.onEnter;

                return (
                    <input
                        ref={this.textInput}
                        type={this.props.type}
                        onFocus={this.props.autoSelect ? this.handleFocus : undefined}
                        onKeyDown={this.props.onEnter ? this.onKeyDown : undefined}
                        {...propsCopy}
                        // value={this.props.value()}
                        value={this.unpack(propsCopy.value)}
                    />
                );
        }
    }

    private unpack(value: (() => string) | string): string {
        if (typeof value === "function") {
            return this.unpack(value());
        } else {
            return value;
        }
    }

    private handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.select();
    }

    private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === 13 && this.props.onEnter) {
            this.props.onEnter(event);
        }
    }
}
