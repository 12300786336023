import * as React from "react";
import {InlineSvg} from "../Svg/InlineSvg";
import * as styles from "./StandardButton.less";
import {CSSProperties} from "react";

interface IProps {
    name?: string;
    iconName?: string;
    className?: string;
    disabled?: boolean;
    style?: CSSProperties;

    onClick?(event: React.MouseEvent): void;
}

export class StandardButton extends React.PureComponent<IProps> {
    public static defaultProps = {
        className: "",
        disabled: false,
        name: "",
        onClick: () => {
            console.error("No click handler");
        }
    };

    public render() {
        return (
            <button
                name={this.props.name}
                disabled={this.props.disabled}
                style={this.props.style}
                className={styles.standardbutton + " " + this.props.className}
                onClick={this.props.onClick}
            >
                <div className={styles.wrapper}>
                    {this.props.iconName && <div className={styles.icon}>
                        <InlineSvg name={this.props.iconName}/>
                    </div>}
                    <div>{this.props.children}</div>
                </div>
            </button>
        );
    }
}
