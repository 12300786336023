import * as React from "react";
import styles from "./StandardFormattingInput.less";
import stdStyles from "./StandardInput.less";
import {Formatter} from "../../class/class.Formatter";

class StandardInput extends React.Component {
    render() {
        const propsCopy = Object.assign({}, this.props);
        propsCopy.className = [stdStyles.standardinput, propsCopy.className].join(" ");
        delete propsCopy.autoSelect;
        delete propsCopy.onEnter;
        return (
            <input
                ref={this.textInput}
                onFocus={this.props.autoSelect ? this.handleFocus : undefined}
                onKeyDown={this.props.onEnter ? this.onKeyDown : undefined}
                {...propsCopy}
            />
        );
    }
}

export class StandardFormattingInput extends React.Component {
    static defaultProps = {
        autoComplete: "off",
        autoFocus: false,
        autoSelect: false,
        className: "",
        disabled: false,
        name: "",
        onChange: function() {
            console.error("No change handler");
        },
        suffix: "€",
        type: "euro",
        decimals: false
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            if (this.props.type === "percent") {
                this.setState({value: (this.props.value || 0) * 100});
            } else {
                const value = this.props.value || "0";

                this.setState({value: value.toString().replace(".", ",")});
            }
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            value: props.value || 0
        };

        if (props.type === "percent") {
            this.state.value = (this.props.value || 0) * 100;
        }

        this.state.value = this.state.value.toString().replace(".", ",");
    }

    handleChange = (event) => {
        const value = event.target.value;
        this.setState({value: value});
    }

    handleBlur = () => {
        this.setState({active: false});
        let value = Formatter.parseFloat(this.state.value);
        if (this.props.type === "percent") {
            value /= 100;
        }
        const event = {
            target: {
                value: value
            }
        };
        this.props.onChange(event);
    }

    handleFocus = (event) => {
        const eventTarget = event.target;
        this.setState({active: true}, function() {
            setTimeout(function() {
                eventTarget.setSelectionRange(0, eventTarget.value.length);
            }, 50);
        });
    }

    render() {
        let renderedFloat = Formatter.toEuro(Formatter.parseFloat(this.state.value), "");
        if (this.props.decimals !== false) {
            renderedFloat = Formatter.floatToString(Formatter.floatToString(Formatter.parseFloat(this.state.value)))
        }

        let input = (
            <StandardInput
                {...this.props}
                decimals={undefined}
                type="text"
                onFocus={this.handleFocus}
                value={Formatter.toEuro(Formatter.parseFloat(this.state.value), "")}
                className={styles.input}
                style={{}}
            />
        );
        if (this.state.active) {
            input = (
                <StandardInput
                    {...this.props}
                    type="text"
                    autoFocus={true}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    value={this.state.value}
                    className={styles.input}
                    style={{}}
                />
            );
        }

        return (
            <div className={styles.container} style={this.props.style}>
                {input}
                <div className={styles.suffix}>{this.props.suffix}</div>
            </div>
        );
    }
}
