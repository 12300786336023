/**
 * Gibt den wahren basispfad zurück oder hängt ihn vor den übergebenen string;
 *
 * @returns string projectOrigin
 */

export class Location {
    public static getHref() {
        return window.location.origin + window.location.pathname;
    }

    public static getOrigin() {
        return window.location.origin;
    }
}

export function getProjectOrigin(somepath = "") {
    const href = Location.getHref().replace(/\/$|\/login\.php/, "").replace(Location.getOrigin(), "");
    return href + somepath;
}
