import {Config} from "./class.Config";

export class Formatter {

    public static toEuro(value: number | string, currency: string = "€"): string {
        let decimals = 2;
        const sessionDecimals = parseInt(Config.getInSession("decimals"), 10);
        if (isFinite(sessionDecimals)) {
            decimals = sessionDecimals;
        }

        return Formatter.floatToString(Formatter.parseFloat(value), currency, decimals);
    }

    public static roundTo(num: number, decimals: number = 2): number {
        num = parseFloat(num.toFixed(decimals + 2));
        // mit dieser Schreibweise wird der Kommazahlen bug von javascript umgangen
        return +(Math.round(+(num + `e+${decimals}`)) + `e-${decimals}`);
    }

    public static floatToString(value: number | string, suffix: string = "",
                                maximiumFractionDigits: number = 2, minimumFractionDigits: number = 2) {
        value = Formatter.parseFloat(value);
        const isNegative = value < 0;
        if (isNegative) {
            value *= -1;
        }

        const rounded = Formatter.roundTo(value, maximiumFractionDigits);
        const valueStringArray = rounded.toString().split(".");
        const integer = valueStringArray[0];
        let fraction = valueStringArray[1];

        if (!fraction) {
            fraction = "";
        }
        const targetFraction = fraction.split("");

        for (let i = 0; i < minimumFractionDigits; i++) {
            targetFraction[i] = fraction.hasOwnProperty(i) ? fraction[i] : "0";
        }

        let counter = 0;
        const targetInteger = [];
        for (let i = integer.length - 1; i >= 0; i--) {
            if (counter === 3) {
                counter = 0;
                targetInteger.unshift(".");
            }
            counter++;
            targetInteger.unshift(integer[i]);
        }

        let fractionPart = "";
        if (targetFraction.length > 0) {
            fractionPart = "," + targetFraction.join("");
        }

        let suffixPart = "";
        if (suffix) {
            suffixPart = " " + suffix;
        }

        if (isNegative) {
            return "-" + targetInteger.join("") + fractionPart + suffixPart;
        }
        return targetInteger.join("") + fractionPart + suffixPart;
    }

    /**
     *
     * @param value
     * @returns {string}
     */
    public static toPercent(value: number | string): string {
        return Formatter.floatToString(Formatter.parseFloat(value) * 100, "%", 2, 0);
    }

    /**
     *
     * @param value
     * @returns {string}
     */
    public static toFloat(value: number | string): string {
        return Formatter.parseFloat(value).toString().replace(".", ",");
    }

    public static parseFloat(input: string | number): number {
        const output = Number(String(input).replace(",", "."));
        if (!isFinite(output)
            || output === -0) {
            return 0;
        }
        return output;
    }

    public static parseDate(dateString: string | Date): string | Date {
        if (dateString instanceof Date) {
            return dateString;
        }
        const regexDSdmyhm = /^([0-9]+)[.,]([0-9]+)[.,]([0-9]+) ([0-9]+):([0-9]+)$/;
        const regexDSdmyh = /^([0-9]+)[.,]([0-9]+)[.,]([0-9]+) ([0-9]+):?$/;
        const regexDSdmy = /^([0-9]+)[.,]([0-9]+)[.,]([0-9]+)$/;
        const regexDSdm = /^([0-9]+)[.,]([0-9]+)[.,]?$/;
        const regexDSd = /^([0-9]+)[.,]?$/;
        const regexDSdates = [regexDSdmyhm, regexDSdmyh, regexDSdmy, regexDSdm, regexDSd];

        const regexDScalc = /^([+-])([0-9]+)$/;

        const date = new Date();
        const dateData = [date.getFullYear(), date.getMonth() + 1, date.getDate(), 12, 0, 0, 0];

        const createYear = function(year: number, baseYear: number = (new Date()).getFullYear()) {
            let returnYear = year;
            if (String(year).length === 2) {
                const limiter = parseInt(baseYear.toString().substr(-2), 10) + 10;
                returnYear = year + (year > limiter ? 1900 : 2000);
            }
            return returnYear;
        };

        function cleanMatches(matches: string[]): number[] {
            matches.shift();
            return matches.map(function(value) {
                return parseInt(value, 10);
            });
        }

        dateString = dateString.trim();
        let cleanedMatches: number[] = [];

        const regexDSdate = regexDSdates.find(function(this: string, element) {
            return element.test(this);
        }, dateString);

        if (regexDSdate) {
            const matches = dateString.match(regexDSdate) || [];
            cleanedMatches = cleanMatches(matches);
        } else if (regexDScalc.test(dateString)) {
            const matches = dateString.match(regexDScalc) || [];
            switch (matches[1]) {
                case "+":
                case "-":
                    dateData[2] += parseInt(matches[1] + matches[2], 10);
                    break;
            }
        } else {
            return dateString;
        }

        dateData[0] = createYear(cleanedMatches[2], dateData[0]) || dateData[0];
        dateData[1] = (cleanedMatches[1] || dateData[1]) - 1;
        dateData[2] = cleanedMatches[0] || dateData[2];
        dateData[3] = cleanedMatches[3] || dateData[3];
        dateData[4] = cleanedMatches[4] || dateData[4];
        return new Date(dateData[0], dateData[1], dateData[2], dateData[3], dateData[4], dateData[5], dateData[6]);
    }

    public static html2text(html: string) {
        const tag = document.createElement("div");
        tag.innerHTML = html;
        // .innerText verhält sich im Firefox wie textContent und Node (JSDOM) kann nur textContent
        return tag.textContent;
    }

    public static dateTime2string(date: Date | string) {
        const pad = Formatter.leadingZero;

        if (date && date instanceof Date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const hour = date.getHours();
            const minutes = date.getMinutes();
            return pad(day) + "." + pad(month) + "." + year + ", " + pad(hour) + ":" + pad(minutes);
        }
    }

    public static date2string(date: Date | string) {
        const pad = Formatter.leadingZero;

        if (date && date instanceof Date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return pad(day) + "." + pad(month) + "." + year;
        }
    }

    public static isIsoDateString(isoString: string) {
        return /^\s*([0-9]{4}(-[0-9]{2}){2})/.test(isoString) && Formatter.toDate(isoString) instanceof Date;
    }

    public static toDate(isoLikeString: string) {
        // Webkit Workaround
        const regex = /([0-9]{4}-[0-9]{2}-[0-9]{2}) ([0-9]{2}:[0-9]{2}:[0-9]{2})/;
        if (regex.test(isoLikeString)) {
            const parts = isoLikeString.match(regex) || [];
            isoLikeString = parts[1] + "T" + parts[2] + "Z";
        }
        const date = new Date(isoLikeString);
        if (!isNaN(date.getTime())) {
            return date;
        }
    }

    public static leadingZero(num: number, size = 2) {
        let s = num + "";
        if (size - s.length > 0) {
            s = Array(size - s.length).fill("0").join("") + s;
        }
        return s;
    }

    public static dateTime2IsoString(date: Date | string): string {
        if (!(date instanceof Date)) {
            date = Formatter.parseDate(date);
        }

        if (date instanceof Date) {
            const tzo = -date.getTimezoneOffset();
            const dif = tzo >= 0 ? "+" : "-";
            const pad = Formatter.leadingZero;
            return (
                date.getFullYear() +
                "-" +
                pad(date.getMonth() + 1) +
                "-" +
                pad(date.getDate()) +
                "T" +
                pad(date.getHours()) +
                ":" +
                pad(date.getMinutes()) +
                ":" +
                pad(date.getSeconds()) +
                dif +
                pad(tzo / 60) +
                ":" +
                pad(tzo % 60)
            );
        } else {
            return date;
        }
    }

    /**
     * die Differenz zwischen date1 und date2 in float Tagen
     * @param date1
     * @param date2
     */
    public static dateDiffernce(date1: Date, date2: Date): number {
        return (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24);
    }
}
