export const SvgLoader = {
    "add-circle-1": () => import("!!raw-loader!../../../svg/add-circle-1.svg"),
    "airplane": () => import("!!raw-loader!../../../svg/airplane.svg"),
    "alarm": () => import("!!raw-loader!../../../svg/alarm.svg"),
    "alien-head": () => import("!!raw-loader!../../../svg/alien-head.svg"),
    "alien": () => import("!!raw-loader!../../../svg/alien.svg"),
    "alt": () => import("!!raw-loader!../../../svg/alt.svg"),
    "android": () => import("!!raw-loader!../../../svg/android.svg"),
    "apple": () => import("!!raw-loader!../../../svg/apple.svg"),
    "appstore": () => import("!!raw-loader!../../../svg/appstore.svg"),
    "arrow_1": () => import("!!raw-loader!../../../svg/arrow_1.svg"),
    "arrow_2": () => import("!!raw-loader!../../../svg/arrow_2.svg"),
    "arrow_down": () => import("!!raw-loader!../../../svg/arrow_down.svg"),
    "arrow_left": () => import("!!raw-loader!../../../svg/arrow_left.svg"),
    "arrow_right": () => import("!!raw-loader!../../../svg/arrow_right.svg"),
    "arrows_horizontal_2": () => import("!!raw-loader!../../../svg/arrows_horizontal_2.svg"),
    "arrows_horizontal": () => import("!!raw-loader!../../../svg/arrows_horizontal.svg"),
    "arrows": () => import("!!raw-loader!../../../svg/arrows.svg"),
    "arrows_vertical_2": () => import("!!raw-loader!../../../svg/arrows_vertical_2.svg"),
    "arrows_vertical": () => import("!!raw-loader!../../../svg/arrows_vertical.svg"),
    "arrow_up": () => import("!!raw-loader!../../../svg/arrow_up.svg"),
    "atomic-bomb": () => import("!!raw-loader!../../../svg/atomic-bomb.svg"),
    "at-sign": () => import("!!raw-loader!../../../svg/at-sign.svg"),
    "baby-hanging-toy": () => import("!!raw-loader!../../../svg/baby-hanging-toy.svg"),
    "baby": () => import("!!raw-loader!../../../svg/baby.svg"),
    "baby-trolley": () => import("!!raw-loader!../../../svg/baby-trolley.svg"),
    "backpack": () => import("!!raw-loader!../../../svg/backpack.svg"),
    "ball": () => import("!!raw-loader!../../../svg/ball.svg"),
    "banking-debit-machine": () => import("!!raw-loader!../../../svg/banking-debit-machine.svg"),
    "banking-donation-2": () => import("!!raw-loader!../../../svg/banking-donation-2.svg"),
    "banking-spendings-1": () => import("!!raw-loader!../../../svg/banking-spendings-1.svg"),
    "banking-spendings-2": () => import("!!raw-loader!../../../svg/banking-spendings-2.svg"),
    "banking-spendings-3": () => import("!!raw-loader!../../../svg/banking-spendings-3.svg"),
    "bank-note": () => import("!!raw-loader!../../../svg/bank-note.svg"),
    "bar_chart_1": () => import("!!raw-loader!../../../svg/bar_chart_1.svg"),
    "bar_chart_2": () => import("!!raw-loader!../../../svg/bar_chart_2.svg"),
    "bar_chart_3": () => import("!!raw-loader!../../../svg/bar_chart_3.svg"),
    "bar_graph": () => import("!!raw-loader!../../../svg/bar_graph.svg"),
    "basket_2": () => import("!!raw-loader!../../../svg/basket_2.svg"),
    "basket_add": () => import("!!raw-loader!../../../svg/basket_add.svg"),
    "basket_delete": () => import("!!raw-loader!../../../svg/basket_delete.svg"),
    "basket_ok": () => import("!!raw-loader!../../../svg/basket_ok.svg"),
    "basket_remove": () => import("!!raw-loader!../../../svg/basket_remove.svg"),
    "basket": () => import("!!raw-loader!../../../svg/basket.svg"),
    "battery_1_3": () => import("!!raw-loader!../../../svg/battery_1_3.svg"),
    "battery_2_3": () => import("!!raw-loader!../../../svg/battery_2_3.svg"),
    "battery-charging-1": () => import("!!raw-loader!../../../svg/battery-charging-1.svg"),
    "battery_empty": () => import("!!raw-loader!../../../svg/battery_empty.svg"),
    "battery_full": () => import("!!raw-loader!../../../svg/battery_full.svg"),
    "battery-high": () => import("!!raw-loader!../../../svg/battery-high.svg"),
    "battery-low": () => import("!!raw-loader!../../../svg/battery-low.svg"),
    "beaker-science": () => import("!!raw-loader!../../../svg/beaker-science.svg"),
    "beauty-hand-soap": () => import("!!raw-loader!../../../svg/beauty-hand-soap.svg"),
    "beauty-lipstick": () => import("!!raw-loader!../../../svg/beauty-lipstick.svg"),
    "behance": () => import("!!raw-loader!../../../svg/behance.svg"),
    "bell": () => import("!!raw-loader!../../../svg/bell.svg"),
    "bin_2": () => import("!!raw-loader!../../../svg/bin_2.svg"),
    "binoculars": () => import("!!raw-loader!../../../svg/binoculars.svg"),
    "bin": () => import("!!raw-loader!../../../svg/bin.svg"),
    "bitcoin-cloud": () => import("!!raw-loader!../../../svg/bitcoin-cloud.svg"),
    "bitcoin-laptop": () => import("!!raw-loader!../../../svg/bitcoin-laptop.svg"),
    "bitcoin-network": () => import("!!raw-loader!../../../svg/bitcoin-network.svg"),
    "bitcoin-windows": () => import("!!raw-loader!../../../svg/bitcoin-windows.svg"),
    "block": () => import("!!raw-loader!../../../svg/block.svg"),
    "bluetooth": () => import("!!raw-loader!../../../svg/bluetooth.svg"),
    "book_1": () => import("!!raw-loader!../../../svg/book_1.svg"),
    "book_2": () => import("!!raw-loader!../../../svg/book_2.svg"),
    "bookmark": () => import("!!raw-loader!../../../svg/bookmark.svg"),
    "book": () => import("!!raw-loader!../../../svg/book.svg"),
    "box-2": () => import("!!raw-loader!../../../svg/box-2.svg"),
    "box-add": () => import("!!raw-loader!../../../svg/box-add.svg"),
    "box-check": () => import("!!raw-loader!../../../svg/box-check.svg"),
    "box-handle-1": () => import("!!raw-loader!../../../svg/box-handle-1.svg"),
    "box-subtract": () => import("!!raw-loader!../../../svg/box-subtract.svg"),
    "brightness_high": () => import("!!raw-loader!../../../svg/brightness_high.svg"),
    "brightness_low": () => import("!!raw-loader!../../../svg/brightness_low.svg"),
    "browser_add": () => import("!!raw-loader!../../../svg/browser_add.svg"),
    "browser_delete": () => import("!!raw-loader!../../../svg/browser_delete.svg"),
    "browser_dialog": () => import("!!raw-loader!../../../svg/browser_dialog.svg"),
    "browser_layout_1": () => import("!!raw-loader!../../../svg/browser_layout_1.svg"),
    "browser_layout_2": () => import("!!raw-loader!../../../svg/browser_layout_2.svg"),
    "browser_layout_3": () => import("!!raw-loader!../../../svg/browser_layout_3.svg"),
    "browser_layout_4": () => import("!!raw-loader!../../../svg/browser_layout_4.svg"),
    "browser_layout_5": () => import("!!raw-loader!../../../svg/browser_layout_5.svg"),
    "browser_ok": () => import("!!raw-loader!../../../svg/browser_ok.svg"),
    "browser_remove": () => import("!!raw-loader!../../../svg/browser_remove.svg"),
    "browser": () => import("!!raw-loader!../../../svg/browser.svg"),
    "building-10": () => import("!!raw-loader!../../../svg/building-10.svg"),
    "building-6": () => import("!!raw-loader!../../../svg/building-6.svg"),
    "building-barn": () => import("!!raw-loader!../../../svg/building-barn.svg"),
    "bullseye": () => import("!!raw-loader!../../../svg/bullseye.svg"),
    "bus-2": () => import("!!raw-loader!../../../svg/bus-2.svg"),
    "business-briefcase-cash": () => import("!!raw-loader!../../../svg/business-briefcase-cash.svg"),
    "business-whiteboard": () => import("!!raw-loader!../../../svg/business-whiteboard.svg"),
    "calculator": () => import("!!raw-loader!../../../svg/calculator.svg"),
    "calendar-1": () => import("!!raw-loader!../../../svg/calendar-1.svg"),
    "calendar_add": () => import("!!raw-loader!../../../svg/calendar_add.svg"),
    "calendar_ok": () => import("!!raw-loader!../../../svg/calendar_ok.svg"),
    "calendar": () => import("!!raw-loader!../../../svg/calendar.svg"),
    "calendar_wv_7": () => import("!!raw-loader!../../../svg/calendar_wv_7.svg"),
    "camera-1": () => import("!!raw-loader!../../../svg/camera-1.svg"),
    "camera-live-view-off": () => import("!!raw-loader!../../../svg/camera-live-view-off.svg"),
    "camera": () => import("!!raw-loader!../../../svg/camera.svg"),
    "car-2": () => import("!!raw-loader!../../../svg/car-2.svg"),
    "cassette": () => import("!!raw-loader!../../../svg/cassette.svg"),
    "castle-1": () => import("!!raw-loader!../../../svg/castle-1.svg"),
    "cc-camera-1": () => import("!!raw-loader!../../../svg/cc-camera-1.svg"),
    "chair-1": () => import("!!raw-loader!../../../svg/chair-1.svg"),
    "chart_1_2": () => import("!!raw-loader!../../../svg/chart_1_2.svg"),
    "chart_1_4": () => import("!!raw-loader!../../../svg/chart_1_4.svg"),
    "chart_1_8": () => import("!!raw-loader!../../../svg/chart_1_8.svg"),
    "chart_3_4": () => import("!!raw-loader!../../../svg/chart_3_4.svg"),
    "chart_3_8": () => import("!!raw-loader!../../../svg/chart_3_8.svg"),
    "chart_5_8": () => import("!!raw-loader!../../../svg/chart_5_8.svg"),
    "chart_7_8": () => import("!!raw-loader!../../../svg/chart_7_8.svg"),
    "chart": () => import("!!raw-loader!../../../svg/chart.svg"),
    "chat-bubble-square-1": () => import("!!raw-loader!../../../svg/chat-bubble-square-1.svg"),
    "chat-bubble-square-add": () => import("!!raw-loader!../../../svg/chat-bubble-square-add.svg"),
    "chat-bubble-square-check": () => import("!!raw-loader!../../../svg/chat-bubble-square-check.svg"),
    "chat-bubble-square-close": () => import("!!raw-loader!../../../svg/chat-bubble-square-close.svg"),
    "chat-bubble-square-smiley": () => import("!!raw-loader!../../../svg/chat-bubble-square-smiley.svg"),
    "chat-bubble-square-subtract": () => import("!!raw-loader!../../../svg/chat-bubble-square-subtract.svg"),
    "checkbox_false": () => import("!!raw-loader!../../../svg/checkbox_false.svg"),
    "check-box": () => import("!!raw-loader!../../../svg/check-box.svg"),
    "checkbox_true": () => import("!!raw-loader!../../../svg/checkbox_true.svg"),
    "chef-1": () => import("!!raw-loader!../../../svg/chef-1.svg"),
    "chef-hat": () => import("!!raw-loader!../../../svg/chef-hat.svg"),
    "circle_delete": () => import("!!raw-loader!../../../svg/circle_delete.svg"),
    "circle_down": () => import("!!raw-loader!../../../svg/circle_down.svg"),
    "circle_fast_forward": () => import("!!raw-loader!../../../svg/circle_fast_forward.svg"),
    "circle_left": () => import("!!raw-loader!../../../svg/circle_left.svg"),
    "circle_minus": () => import("!!raw-loader!../../../svg/circle_minus.svg"),
    "circle_ok": () => import("!!raw-loader!../../../svg/circle_ok.svg"),
    "circle_pause": () => import("!!raw-loader!../../../svg/circle_pause.svg"),
    "circle_play": () => import("!!raw-loader!../../../svg/circle_play.svg"),
    "circle_plus": () => import("!!raw-loader!../../../svg/circle_plus.svg"),
    "circle_rewind": () => import("!!raw-loader!../../../svg/circle_rewind.svg"),
    "circle_right": () => import("!!raw-loader!../../../svg/circle_right.svg"),
    "circle_skip_next": () => import("!!raw-loader!../../../svg/circle_skip_next.svg"),
    "circle_skip_previous": () => import("!!raw-loader!../../../svg/circle_skip_previous.svg"),
    "circle_stop": () => import("!!raw-loader!../../../svg/circle_stop.svg"),
    "circle": () => import("!!raw-loader!../../../svg/circle.svg"),
    "circle_up": () => import("!!raw-loader!../../../svg/circle_up.svg"),
    "clip": () => import("!!raw-loader!../../../svg/clip.svg"),
    "close": () => import("!!raw-loader!../../../svg/close.svg"),
    "cloud_download": () => import("!!raw-loader!../../../svg/cloud_download.svg"),
    "cloud_fail": () => import("!!raw-loader!../../../svg/cloud_fail.svg"),
    "cloud-harddisk-1": () => import("!!raw-loader!../../../svg/cloud-harddisk-1.svg"),
    "cloud_ok": () => import("!!raw-loader!../../../svg/cloud_ok.svg"),
    "cloud": () => import("!!raw-loader!../../../svg/cloud.svg"),
    "cloud_upload": () => import("!!raw-loader!../../../svg/cloud_upload.svg"),
    "code": () => import("!!raw-loader!../../../svg/code.svg"),
    "coffee-mug": () => import("!!raw-loader!../../../svg/coffee-mug.svg"),
    "cog-box": () => import("!!raw-loader!../../../svg/cog-box.svg"),
    "cog": () => import("!!raw-loader!../../../svg/cog.svg"),
    "coin-purse-1": () => import("!!raw-loader!../../../svg/coin-purse-1.svg"),
    "coin-stack-1": () => import("!!raw-loader!../../../svg/coin-stack-1.svg"),
    "color-palette": () => import("!!raw-loader!../../../svg/color-palette.svg"),
    "columns": () => import("!!raw-loader!../../../svg/columns.svg"),
    "command": () => import("!!raw-loader!../../../svg/command.svg"),
    "comment_bubble": () => import("!!raw-loader!../../../svg/comment_bubble.svg"),
    "comment_info": () => import("!!raw-loader!../../../svg/comment_info.svg"),
    "comment_qna": () => import("!!raw-loader!../../../svg/comment_qna.svg"),
    "compass": () => import("!!raw-loader!../../../svg/compass.svg"),
    "computer_add": () => import("!!raw-loader!../../../svg/computer_add.svg"),
    "computer_delete": () => import("!!raw-loader!../../../svg/computer_delete.svg"),
    "computer_download": () => import("!!raw-loader!../../../svg/computer_download.svg"),
    "computer_ok": () => import("!!raw-loader!../../../svg/computer_ok.svg"),
    "computer-personal-1": () => import("!!raw-loader!../../../svg/computer-personal-1.svg"),
    "computer_remove": () => import("!!raw-loader!../../../svg/computer_remove.svg"),
    "computer-screen-1": () => import("!!raw-loader!../../../svg/computer-screen-1.svg"),
    "computer_upload": () => import("!!raw-loader!../../../svg/computer_upload.svg"),
    "content-book-2": () => import("!!raw-loader!../../../svg/content-book-2.svg"),
    "content-book-6": () => import("!!raw-loader!../../../svg/content-book-6.svg"),
    "content-bookmark-4": () => import("!!raw-loader!../../../svg/content-bookmark-4.svg"),
    "convert_check": () => import("!!raw-loader!../../../svg/convert_check.svg"),
    "convert_plus_2": () => import("!!raw-loader!../../../svg/convert_plus_2.svg"),
    "convert_plus": () => import("!!raw-loader!../../../svg/convert_plus.svg"),
    "copy_1": () => import("!!raw-loader!../../../svg/copy_1.svg"),
    "copy_2": () => import("!!raw-loader!../../../svg/copy_2.svg"),
    "couch": () => import("!!raw-loader!../../../svg/couch.svg"),
    "credit_card": () => import("!!raw-loader!../../../svg/credit_card.svg"),
    "crop-image": () => import("!!raw-loader!../../../svg/crop-image.svg"),
    "crop": () => import("!!raw-loader!../../../svg/crop.svg"),
    "cursor-arrow-1": () => import("!!raw-loader!../../../svg/cursor-arrow-1.svg"),
    "cursor-arrow-2": () => import("!!raw-loader!../../../svg/cursor-arrow-2.svg"),
    "cursor-touch-1": () => import("!!raw-loader!../../../svg/cursor-touch-1.svg"),
    "database_down": () => import("!!raw-loader!../../../svg/database_down.svg"),
    "database_left": () => import("!!raw-loader!../../../svg/database_left.svg"),
    "database_right": () => import("!!raw-loader!../../../svg/database_right.svg"),
    "database": () => import("!!raw-loader!../../../svg/database.svg"),
    "database_up": () => import("!!raw-loader!../../../svg/database_up.svg"),
    "data-download-5": () => import("!!raw-loader!../../../svg/data-download-5.svg"),
    "data-upload-5": () => import("!!raw-loader!../../../svg/data-upload-5.svg"),
    "devices": () => import("!!raw-loader!../../../svg/devices.svg"),
    "discount2": () => import("!!raw-loader!../../../svg/discount2.svg"),
    "discount": () => import("!!raw-loader!../../../svg/discount.svg"),
    "diskette-close": () => import("!!raw-loader!../../../svg/diskette-close.svg"),
    "diskette": () => import("!!raw-loader!../../../svg/diskette.svg"),
    "dispo": () => import("!!raw-loader!../../../svg/dispo.svg"),
    "dna": () => import("!!raw-loader!../../../svg/dna.svg"),
    "document_add": () => import("!!raw-loader!../../../svg/document_add.svg"),
    "document_delete": () => import("!!raw-loader!../../../svg/document_delete.svg"),
    "document_download": () => import("!!raw-loader!../../../svg/document_download.svg"),
    "document_ok": () => import("!!raw-loader!../../../svg/document_ok.svg"),
    "document_remove": () => import("!!raw-loader!../../../svg/document_remove.svg"),
    "document_search": () => import("!!raw-loader!../../../svg/document_search.svg"),
    "documents": () => import("!!raw-loader!../../../svg/documents.svg"),
    "document": () => import("!!raw-loader!../../../svg/document.svg"),
    "document_upload": () => import("!!raw-loader!../../../svg/document_upload.svg"),
    "document_view": () => import("!!raw-loader!../../../svg/document_view.svg"),
    "download-computer": () => import("!!raw-loader!../../../svg/download-computer.svg"),
    "download": () => import("!!raw-loader!../../../svg/download.svg"),
    "dribbble": () => import("!!raw-loader!../../../svg/dribbble.svg"),
    "dropbox": () => import("!!raw-loader!../../../svg/dropbox.svg"),
    "drop": () => import("!!raw-loader!../../../svg/drop.svg"),
    "eco-car": () => import("!!raw-loader!../../../svg/eco-car.svg"),
    "eco-factory": () => import("!!raw-loader!../../../svg/eco-factory.svg"),
    "eco-field": () => import("!!raw-loader!../../../svg/eco-field.svg"),
    "eject": () => import("!!raw-loader!../../../svg/eject.svg"),
    "email-heart": () => import("!!raw-loader!../../../svg/email-heart.svg"),
    "email-inbox": () => import("!!raw-loader!../../../svg/email-inbox.svg"),
    "email-outbox": () => import("!!raw-loader!../../../svg/email-outbox.svg"),
    "email-timeout": () => import("!!raw-loader!../../../svg/email-timeout.svg"),
    "envelope-1": () => import("!!raw-loader!../../../svg/envelope-1.svg"),
    "equalizer": () => import("!!raw-loader!../../../svg/equalizer.svg"),
    "espresso-machine": () => import("!!raw-loader!../../../svg/espresso-machine.svg"),
    "euro": () => import("!!raw-loader!../../../svg/euro.svg"),
    "exposure-level": () => import("!!raw-loader!../../../svg/exposure-level.svg"),
    "facebook_1": () => import("!!raw-loader!../../../svg/facebook_1.svg"),
    "facebook_2": () => import("!!raw-loader!../../../svg/facebook_2.svg"),
    "fast_forward": () => import("!!raw-loader!../../../svg/fast_forward.svg"),
    "female-1": () => import("!!raw-loader!../../../svg/female-1.svg"),
    "fence-caution": () => import("!!raw-loader!../../../svg/fence-caution.svg"),
    "file-bookmark": () => import("!!raw-loader!../../../svg/file-bookmark.svg"),
    "file-copy": () => import("!!raw-loader!../../../svg/file-copy.svg"),
    "file-hand": () => import("!!raw-loader!../../../svg/file-hand.svg"),
    "file-landscape-new-1": () => import("!!raw-loader!../../../svg/file-landscape-new-1.svg"),
    "file-new-1": () => import("!!raw-loader!../../../svg/file-new-1.svg"),
    "file-new-2": () => import("!!raw-loader!../../../svg/file-new-2.svg"),
    "file-notes-document": () => import("!!raw-loader!../../../svg/file-notes-document.svg"),
    "file-notes-new": () => import("!!raw-loader!../../../svg/file-notes-new.svg"),
    "file-office-graph": () => import("!!raw-loader!../../../svg/file-office-graph.svg"),
    "file-office-text": () => import("!!raw-loader!../../../svg/file-office-text.svg"),
    "files-1": () => import("!!raw-loader!../../../svg/files-1.svg"),
    "files-2": () => import("!!raw-loader!../../../svg/files-2.svg"),
    "files-3": () => import("!!raw-loader!../../../svg/files-3.svg"),
    "file-swap": () => import("!!raw-loader!../../../svg/file-swap.svg"),
    "file-tasks-add": () => import("!!raw-loader!../../../svg/file-tasks-add.svg"),
    "file-tasks-checklist": () => import("!!raw-loader!../../../svg/file-tasks-checklist.svg"),
    "file-tasks-close": () => import("!!raw-loader!../../../svg/file-tasks-close.svg"),
    "file-tasks-subtract": () => import("!!raw-loader!../../../svg/file-tasks-subtract.svg"),
    "file-zipped-new": () => import("!!raw-loader!../../../svg/file-zipped-new.svg"),
    "file-zipped-vice": () => import("!!raw-loader!../../../svg/file-zipped-vice.svg"),
    "fire-extinguisher": () => import("!!raw-loader!../../../svg/fire-extinguisher.svg"),
    "fire-lighter": () => import("!!raw-loader!../../../svg/fire-lighter.svg"),
    "first_aid": () => import("!!raw-loader!../../../svg/first_aid.svg"),
    "flash-off": () => import("!!raw-loader!../../../svg/flash-off.svg"),
    "flash": () => import("!!raw-loader!../../../svg/flash.svg"),
    "folder-add": () => import("!!raw-loader!../../../svg/folder-add.svg"),
    "folder_add": () => import("!!raw-loader!../../../svg/folder_add.svg"),
    "folder_back": () => import("!!raw-loader!../../../svg/folder_back.svg"),
    "folder-check": () => import("!!raw-loader!../../../svg/folder-check.svg"),
    "folder-close": () => import("!!raw-loader!../../../svg/folder-close.svg"),
    "folder_delete": () => import("!!raw-loader!../../../svg/folder_delete.svg"),
    "folder_ok": () => import("!!raw-loader!../../../svg/folder_ok.svg"),
    "folder_remove": () => import("!!raw-loader!../../../svg/folder_remove.svg"),
    "folder-subtract": () => import("!!raw-loader!../../../svg/folder-subtract.svg"),
    "folder": () => import("!!raw-loader!../../../svg/folder.svg"),
    "followers": () => import("!!raw-loader!../../../svg/followers.svg"),
    "following": () => import("!!raw-loader!../../../svg/following.svg"),
    "font_size_down": () => import("!!raw-loader!../../../svg/font_size_down.svg"),
    "font_size_up": () => import("!!raw-loader!../../../svg/font_size_up.svg"),
    "food-chicken-drum-stick": () => import("!!raw-loader!../../../svg/food-chicken-drum-stick.svg"),
    "food-double-burger": () => import("!!raw-loader!../../../svg/food-double-burger.svg"),
    "food-icecream-2": () => import("!!raw-loader!../../../svg/food-icecream-2.svg"),
    "food-noodle-2": () => import("!!raw-loader!../../../svg/food-noodle-2.svg"),
    "food-plate-knife-fork": () => import("!!raw-loader!../../../svg/food-plate-knife-fork.svg"),
    "food-steak": () => import("!!raw-loader!../../../svg/food-steak.svg"),
    "forrst": () => import("!!raw-loader!../../../svg/forrst.svg"),
    "foursquare": () => import("!!raw-loader!../../../svg/foursquare.svg"),
    "frame": () => import("!!raw-loader!../../../svg/frame.svg"),
    "glass-cocktail-2": () => import("!!raw-loader!../../../svg/glass-cocktail-2.svg"),
    "globe": () => import("!!raw-loader!../../../svg/globe.svg"),
    "gold-nuggets": () => import("!!raw-loader!../../../svg/gold-nuggets.svg"),
    "google_plus": () => import("!!raw-loader!../../../svg/google_plus.svg"),
    "graduation-hat": () => import("!!raw-loader!../../../svg/graduation-hat.svg"),
    "gramophone": () => import("!!raw-loader!../../../svg/gramophone.svg"),
    "graph_fall": () => import("!!raw-loader!../../../svg/graph_fall.svg"),
    "graph_rise": () => import("!!raw-loader!../../../svg/graph_rise.svg"),
    "greek-column": () => import("!!raw-loader!../../../svg/greek-column.svg"),
    "grid_1": () => import("!!raw-loader!../../../svg/grid_1.svg"),
    "grid_2": () => import("!!raw-loader!../../../svg/grid_2.svg"),
    "hammer-anvil": () => import("!!raw-loader!../../../svg/hammer-anvil.svg"),
    "hand-gun": () => import("!!raw-loader!../../../svg/hand-gun.svg"),
    "hand-remote_2": () => import("!!raw-loader!../../../svg/hand-remote_2.svg"),
    "hand-remote": () => import("!!raw-loader!../../../svg/hand-remote.svg"),
    "hanging-rope": () => import("!!raw-loader!../../../svg/hanging-rope.svg"),
    "hashtag": () => import("!!raw-loader!../../../svg/hashtag.svg"),
    "hat-1": () => import("!!raw-loader!../../../svg/hat-1.svg"),
    "hat-magician": () => import("!!raw-loader!../../../svg/hat-magician.svg"),
    "health-ambulance": () => import("!!raw-loader!../../../svg/health-ambulance.svg"),
    "health-graph-1": () => import("!!raw-loader!../../../svg/health-graph-1.svg"),
    "health-heart-pulse": () => import("!!raw-loader!../../../svg/health-heart-pulse.svg"),
    "health-hospital-sign-1": () => import("!!raw-loader!../../../svg/health-hospital-sign-1.svg"),
    "health-medicine-bottle": () => import("!!raw-loader!../../../svg/health-medicine-bottle.svg"),
    "health-prescription-2": () => import("!!raw-loader!../../../svg/health-prescription-2.svg"),
    "heart": () => import("!!raw-loader!../../../svg/heart.svg"),
    "helicopter": () => import("!!raw-loader!../../../svg/helicopter.svg"),
    "home": () => import("!!raw-loader!../../../svg/home.svg"),
    "hot-air-balloon": () => import("!!raw-loader!../../../svg/hot-air-balloon.svg"),
    "hotel-bath-shower": () => import("!!raw-loader!../../../svg/hotel-bath-shower.svg"),
    "hotel-bed-1": () => import("!!raw-loader!../../../svg/hotel-bed-1.svg"),
    "hotel-shower": () => import("!!raw-loader!../../../svg/hotel-shower.svg"),
    "hotel-toilet-seat": () => import("!!raw-loader!../../../svg/hotel-toilet-seat.svg"),
    "hourglass": () => import("!!raw-loader!../../../svg/hourglass.svg"),
    "id-card-1": () => import("!!raw-loader!../../../svg/id-card-1.svg"),
    "imac": () => import("!!raw-loader!../../../svg/imac.svg"),
    "image": () => import("!!raw-loader!../../../svg/image.svg"),
    "inbox_in": () => import("!!raw-loader!../../../svg/inbox_in.svg"),
    "inbox_out": () => import("!!raw-loader!../../../svg/inbox_out.svg"),
    "inbox": () => import("!!raw-loader!../../../svg/inbox.svg"),
    "information": () => import("!!raw-loader!../../../svg/information.svg"),
    "instagram": () => import("!!raw-loader!../../../svg/instagram.svg"),
    "ipad": () => import("!!raw-loader!../../../svg/ipad.svg"),
    "iphone": () => import("!!raw-loader!../../../svg/iphone.svg"),
    "keyboard": () => import("!!raw-loader!../../../svg/keyboard.svg"),
    "key-hole-1": () => import("!!raw-loader!../../../svg/key-hole-1.svg"),
    "king": () => import("!!raw-loader!../../../svg/king.svg"),
    "kitchen-blender": () => import("!!raw-loader!../../../svg/kitchen-blender.svg"),
    "kitchen-frying-machine": () => import("!!raw-loader!../../../svg/kitchen-frying-machine.svg"),
    "kitchen-pan-fry": () => import("!!raw-loader!../../../svg/kitchen-pan-fry.svg"),
    "lamp-1": () => import("!!raw-loader!../../../svg/lamp-1.svg"),
    "lamp-studio-1": () => import("!!raw-loader!../../../svg/lamp-studio-1.svg"),
    "laptop-1": () => import("!!raw-loader!../../../svg/laptop-1.svg"),
    "layers": () => import("!!raw-loader!../../../svg/layers.svg"),
    "leisure-dj-booth": () => import("!!raw-loader!../../../svg/leisure-dj-booth.svg"),
    "leisure-magic": () => import("!!raw-loader!../../../svg/leisure-magic.svg"),
    "leisure-marry-go-round": () => import("!!raw-loader!../../../svg/leisure-marry-go-round.svg"),
    "leisure-rest": () => import("!!raw-loader!../../../svg/leisure-rest.svg"),
    "line_graph_square": () => import("!!raw-loader!../../../svg/line_graph_square.svg"),
    "line_graph": () => import("!!raw-loader!../../../svg/line_graph.svg"),
    "linkedin": () => import("!!raw-loader!../../../svg/linkedin.svg"),
    "link": () => import("!!raw-loader!../../../svg/link.svg"),
    "list_2": () => import("!!raw-loader!../../../svg/list_2.svg"),
    "list": () => import("!!raw-loader!../../../svg/list.svg"),
    "lo_add_marked": () => import("!!raw-loader!../../../svg/lo_add_marked.svg"),
    "lo_add_to_pinlist": () => import("!!raw-loader!../../../svg/lo_add_to_pinlist.svg"),
    "loading": () => import("!!raw-loader!../../../svg/loading.svg"),
    "location-gps-on-2": () => import("!!raw-loader!../../../svg/location-gps-on-2.svg"),
    "location-map-1": () => import("!!raw-loader!../../../svg/location-map-1.svg"),
    "location-pin-4": () => import("!!raw-loader!../../../svg/location-pin-4.svg"),
    "location-pin-add-2": () => import("!!raw-loader!../../../svg/location-pin-add-2.svg"),
    "location-pin-bank-2": () => import("!!raw-loader!../../../svg/location-pin-bank-2.svg"),
    "location-pin-check-2": () => import("!!raw-loader!../../../svg/location-pin-check-2.svg"),
    "location-pin-close-2": () => import("!!raw-loader!../../../svg/location-pin-close-2.svg"),
    "location-pin-skull-2": () => import("!!raw-loader!../../../svg/location-pin-skull-2.svg"),
    "location-pin-subtract-2": () => import("!!raw-loader!../../../svg/location-pin-subtract-2.svg"),
    "location": () => import("!!raw-loader!../../../svg/location.svg"),
    "location-user": () => import("!!raw-loader!../../../svg/location-user.svg"),
    "lock-1": () => import("!!raw-loader!../../../svg/lock-1.svg"),
    "lock": () => import("!!raw-loader!../../../svg/lock.svg"),
    "lock-unlock-1": () => import("!!raw-loader!../../../svg/lock-unlock-1.svg"),
    "lo_delete_selection": () => import("!!raw-loader!../../../svg/lo_delete_selection.svg"),
    "login-check": () => import("!!raw-loader!../../../svg/login-check.svg"),
    "login-lock": () => import("!!raw-loader!../../../svg/login-lock.svg"),
    "login": () => import("!!raw-loader!../../../svg/login.svg"),
    "login-wrong": () => import("!!raw-loader!../../../svg/login-wrong.svg"),
    "logout": () => import("!!raw-loader!../../../svg/logout.svg"),
    "lo_hide_marked": () => import("!!raw-loader!../../../svg/lo_hide_marked.svg"),
    "lo_load_marked": () => import("!!raw-loader!../../../svg/lo_load_marked.svg"),
    "lo_load_selection": () => import("!!raw-loader!../../../svg/lo_load_selection.svg"),
    "lo_remove_from_pinlist": () => import("!!raw-loader!../../../svg/lo_remove_from_pinlist.svg"),
    "lo_remove_marked": () => import("!!raw-loader!../../../svg/lo_remove_marked.svg"),
    "lo_save_marked": () => import("!!raw-loader!../../../svg/lo_save_marked.svg"),
    "lo_show_all": () => import("!!raw-loader!../../../svg/lo_show_all.svg"),
    "lo_show_pinlist": () => import("!!raw-loader!../../../svg/lo_show_pinlist.svg"),
    "macbook": () => import("!!raw-loader!../../../svg/macbook.svg"),
    "magnifying_glass_add": () => import("!!raw-loader!../../../svg/magnifying_glass_add.svg"),
    "magnifying_glass_remove": () => import("!!raw-loader!../../../svg/magnifying_glass_remove.svg"),
    "magnifying_glass": () => import("!!raw-loader!../../../svg/magnifying_glass.svg"),
    "mail_incoming": () => import("!!raw-loader!../../../svg/mail_incoming.svg"),
    "mail_outgoing": () => import("!!raw-loader!../../../svg/mail_outgoing.svg"),
    "mail-refresh-1": () => import("!!raw-loader!../../../svg/mail-refresh-1.svg"),
    "mail": () => import("!!raw-loader!../../../svg/mail.svg"),
    "map_add": () => import("!!raw-loader!../../../svg/map_add.svg"),
    "map_delete": () => import("!!raw-loader!../../../svg/map_delete.svg"),
    "map_ok": () => import("!!raw-loader!../../../svg/map_ok.svg"),
    "map_remove": () => import("!!raw-loader!../../../svg/map_remove.svg"),
    "map": () => import("!!raw-loader!../../../svg/map.svg"),
    "mark_all": () => import("!!raw-loader!../../../svg/mark_all.svg"),
    "mark_none": () => import("!!raw-loader!../../../svg/mark_none.svg"),
    "mark_swap2": () => import("!!raw-loader!../../../svg/mark_swap2.svg"),
    "mark_swap": () => import("!!raw-loader!../../../svg/mark_swap.svg"),
    "match-stick": () => import("!!raw-loader!../../../svg/match-stick.svg"),
    "microphone_off": () => import("!!raw-loader!../../../svg/microphone_off.svg"),
    "microphone": () => import("!!raw-loader!../../../svg/microphone.svg"),
    "microsoft": () => import("!!raw-loader!../../../svg/microsoft.svg"),
    "minus": () => import("!!raw-loader!../../../svg/minus.svg"),
    "mobile-phone-audio": () => import("!!raw-loader!../../../svg/mobile-phone-audio.svg"),
    "mobile-phone-check-1": () => import("!!raw-loader!../../../svg/mobile-phone-check-1.svg"),
    "mobile-phone-close-1": () => import("!!raw-loader!../../../svg/mobile-phone-close-1.svg"),
    "mobile-phone-off": () => import("!!raw-loader!../../../svg/mobile-phone-off.svg"),
    "monster-truck-1": () => import("!!raw-loader!../../../svg/monster-truck-1.svg"),
    "more_2": () => import("!!raw-loader!../../../svg/more_2.svg"),
    "more": () => import("!!raw-loader!../../../svg/more.svg"),
    "motorcycle-1": () => import("!!raw-loader!../../../svg/motorcycle-1.svg"),
    "motorcycle-2": () => import("!!raw-loader!../../../svg/motorcycle-2.svg"),
    "moustache": () => import("!!raw-loader!../../../svg/moustache.svg"),
    "music-note-1": () => import("!!raw-loader!../../../svg/music-note-1.svg"),
    "music": () => import("!!raw-loader!../../../svg/music.svg"),
    "nature-flower-1": () => import("!!raw-loader!../../../svg/nature-flower-1.svg"),
    "nature-plant-1": () => import("!!raw-loader!../../../svg/nature-plant-1.svg"),
    "navigation-before-1": () => import("!!raw-loader!../../../svg/navigation-before-1.svg"),
    "navigation-next-1": () => import("!!raw-loader!../../../svg/navigation-next-1.svg"),
    "network-business": () => import("!!raw-loader!../../../svg/network-business.svg"),
    "network-laptop": () => import("!!raw-loader!../../../svg/network-laptop.svg"),
    "notepad_add": () => import("!!raw-loader!../../../svg/notepad_add.svg"),
    "notepad_delete": () => import("!!raw-loader!../../../svg/notepad_delete.svg"),
    "notepad_ok": () => import("!!raw-loader!../../../svg/notepad_ok.svg"),
    "notepad_remove": () => import("!!raw-loader!../../../svg/notepad_remove.svg"),
    "notepad": () => import("!!raw-loader!../../../svg/notepad.svg"),
    "nuclear-power-plant": () => import("!!raw-loader!../../../svg/nuclear-power-plant.svg"),
    "operator-male": () => import("!!raw-loader!../../../svg/operator-male.svg"),
    "organization-hierarchy-3": () => import("!!raw-loader!../../../svg/organization-hierarchy-3.svg"),
    "origami-paper-bird": () => import("!!raw-loader!../../../svg/origami-paper-bird.svg"),
    "outbox": () => import("!!raw-loader!../../../svg/outbox.svg"),
    "paint-brush-1": () => import("!!raw-loader!../../../svg/paint-brush-1.svg"),
    "paper-pin": () => import("!!raw-loader!../../../svg/paper-pin.svg"),
    "paperplane": () => import("!!raw-loader!../../../svg/paperplane.svg"),
    "pause": () => import("!!raw-loader!../../../svg/pause.svg"),
    "paypal": () => import("!!raw-loader!../../../svg/paypal.svg"),
    "pdf_anzeigen": () => import("!!raw-loader!../../../svg/pdf_anzeigen.svg"),
    "pen_1": () => import("!!raw-loader!../../../svg/pen_1.svg"),
    "pen_2": () => import("!!raw-loader!../../../svg/pen_2.svg"),
    "pen_3": () => import("!!raw-loader!../../../svg/pen_3.svg"),
    "pencil-2": () => import("!!raw-loader!../../../svg/pencil-2.svg"),
    "pencil-ruler": () => import("!!raw-loader!../../../svg/pencil-ruler.svg"),
    "pen-tablet": () => import("!!raw-loader!../../../svg/pen-tablet.svg"),
    "phone-incoming": () => import("!!raw-loader!../../../svg/phone-incoming.svg"),
    "phone-outgoing": () => import("!!raw-loader!../../../svg/phone-outgoing.svg"),
    "photo-landscape": () => import("!!raw-loader!../../../svg/photo-landscape.svg"),
    "photo-portrait": () => import("!!raw-loader!../../../svg/photo-portrait.svg"),
    "picture-layer-1": () => import("!!raw-loader!../../../svg/picture-layer-1.svg"),
    "picture": () => import("!!raw-loader!../../../svg/picture.svg"),
    "pie_chart": () => import("!!raw-loader!../../../svg/pie_chart.svg"),
    "pill": () => import("!!raw-loader!../../../svg/pill.svg"),
    "pin_1": () => import("!!raw-loader!../../../svg/pin_1.svg"),
    "pin-2": () => import("!!raw-loader!../../../svg/pin-2.svg"),
    "pin_2": () => import("!!raw-loader!../../../svg/pin_2.svg"),
    "pin": () => import("!!raw-loader!../../../svg/pin.svg"),
    "pinterest_1": () => import("!!raw-loader!../../../svg/pinterest_1.svg"),
    "pinterest_2": () => import("!!raw-loader!../../../svg/pinterest_2.svg"),
    "pin_zoom_in": () => import("!!raw-loader!../../../svg/pin_zoom_in.svg"),
    "pin_zoom_out": () => import("!!raw-loader!../../../svg/pin_zoom_out.svg"),
    "places-christ-the-redeemer": () => import("!!raw-loader!../../../svg/places-christ-the-redeemer.svg"),
    "places-colosseum": () => import("!!raw-loader!../../../svg/places-colosseum.svg"),
    "places-eiffel-tower": () => import("!!raw-loader!../../../svg/places-eiffel-tower.svg"),
    "places-home-3": () => import("!!raw-loader!../../../svg/places-home-3.svg"),
    "places-home-4": () => import("!!raw-loader!../../../svg/places-home-4.svg"),
    "places-taj-mahal": () => import("!!raw-loader!../../../svg/places-taj-mahal.svg"),
    "places-warehouse-1": () => import("!!raw-loader!../../../svg/places-warehouse-1.svg"),
    "places-water-well": () => import("!!raw-loader!../../../svg/places-water-well.svg"),
    "play": () => import("!!raw-loader!../../../svg/play.svg"),
    "plug": () => import("!!raw-loader!../../../svg/plug.svg"),
    "plus": () => import("!!raw-loader!../../../svg/plus.svg"),
    "pocket-knife": () => import("!!raw-loader!../../../svg/pocket-knife.svg"),
    "polaroids": () => import("!!raw-loader!../../../svg/polaroids.svg"),
    "polaroid": () => import("!!raw-loader!../../../svg/polaroid.svg"),
    "police-officer-1": () => import("!!raw-loader!../../../svg/police-officer-1.svg"),
    "polo-shirt": () => import("!!raw-loader!../../../svg/polo-shirt.svg"),
    "power": () => import("!!raw-loader!../../../svg/power.svg"),
    "presentation": () => import("!!raw-loader!../../../svg/presentation.svg"),
    "printer": () => import("!!raw-loader!../../../svg/printer.svg"),
    "progress": () => import("!!raw-loader!../../../svg/progress.svg"),
    "projector": () => import("!!raw-loader!../../../svg/projector.svg"),
    "quill": () => import("!!raw-loader!../../../svg/quill.svg"),
    "receipt-1": () => import("!!raw-loader!../../../svg/receipt-1.svg"),
    "rechargable-battery": () => import("!!raw-loader!../../../svg/rechargable-battery.svg"),
    "record": () => import("!!raw-loader!../../../svg/record.svg"),
    "register-machine": () => import("!!raw-loader!../../../svg/register-machine.svg"),
    "remove-circle-1": () => import("!!raw-loader!../../../svg/remove-circle-1.svg"),
    "repeat_1": () => import("!!raw-loader!../../../svg/repeat_1.svg"),
    "repeat_2": () => import("!!raw-loader!../../../svg/repeat_2.svg"),
    "repeat": () => import("!!raw-loader!../../../svg/repeat.svg"),
    "resize_1": () => import("!!raw-loader!../../../svg/resize_1.svg"),
    "resize_2": () => import("!!raw-loader!../../../svg/resize_2.svg"),
    "resize_3": () => import("!!raw-loader!../../../svg/resize_3.svg"),
    "resize_4": () => import("!!raw-loader!../../../svg/resize_4.svg"),
    "resize_5": () => import("!!raw-loader!../../../svg/resize_5.svg"),
    "resize_6": () => import("!!raw-loader!../../../svg/resize_6.svg"),
    "rewards-banner-check": () => import("!!raw-loader!../../../svg/rewards-banner-check.svg"),
    "rewards-gift": () => import("!!raw-loader!../../../svg/rewards-gift.svg"),
    "rewards-medal-1": () => import("!!raw-loader!../../../svg/rewards-medal-1.svg"),
    "rewards-pedestal": () => import("!!raw-loader!../../../svg/rewards-pedestal.svg"),
    "rewards-trophy-5": () => import("!!raw-loader!../../../svg/rewards-trophy-5.svg"),
    "rewind": () => import("!!raw-loader!../../../svg/rewind.svg"),
    "ring-planet": () => import("!!raw-loader!../../../svg/ring-planet.svg"),
    "romance-bow": () => import("!!raw-loader!../../../svg/romance-bow.svg"),
    "romance-love-target": () => import("!!raw-loader!../../../svg/romance-love-target.svg"),
    "romance-relationship": () => import("!!raw-loader!../../../svg/romance-relationship.svg"),
    "rss": () => import("!!raw-loader!../../../svg/rss.svg"),
    "safe": () => import("!!raw-loader!../../../svg/safe.svg"),
    "save-water": () => import("!!raw-loader!../../../svg/save-water.svg"),
    "scissors": () => import("!!raw-loader!../../../svg/scissors.svg"),
    "screen_expand_1": () => import("!!raw-loader!../../../svg/screen_expand_1.svg"),
    "screen_expand_2": () => import("!!raw-loader!../../../svg/screen_expand_2.svg"),
    "screen_expand_3": () => import("!!raw-loader!../../../svg/screen_expand_3.svg"),
    "selectbg": () => import("!!raw-loader!../../../svg/selectbg.svg"),
    "send-to-back": () => import("!!raw-loader!../../../svg/send-to-back.svg"),
    "send-to-front": () => import("!!raw-loader!../../../svg/send-to-front.svg"),
    "server-2": () => import("!!raw-loader!../../../svg/server-2.svg"),
    "server-add-1": () => import("!!raw-loader!../../../svg/server-add-1.svg"),
    "server-bug": () => import("!!raw-loader!../../../svg/server-bug.svg"),
    "server-check-1": () => import("!!raw-loader!../../../svg/server-check-1.svg"),
    "server-close-1": () => import("!!raw-loader!../../../svg/server-close-1.svg"),
    "server-network-1": () => import("!!raw-loader!../../../svg/server-network-1.svg"),
    "server-subtract-1": () => import("!!raw-loader!../../../svg/server-subtract-1.svg"),
    "settings-1": () => import("!!raw-loader!../../../svg/settings-1.svg"),
    "share_2": () => import("!!raw-loader!../../../svg/share_2.svg"),
    "share-megaphone-2": () => import("!!raw-loader!../../../svg/share-megaphone-2.svg"),
    "share-radar": () => import("!!raw-loader!../../../svg/share-radar.svg"),
    "share-signal-user": () => import("!!raw-loader!../../../svg/share-signal-user.svg"),
    "share": () => import("!!raw-loader!../../../svg/share.svg"),
    "shopping_bag": () => import("!!raw-loader!../../../svg/shopping_bag.svg"),
    "shopping-basket-1": () => import("!!raw-loader!../../../svg/shopping-basket-1.svg"),
    "shopping-basket-2": () => import("!!raw-loader!../../../svg/shopping-basket-2.svg"),
    "shopping-basket-add": () => import("!!raw-loader!../../../svg/shopping-basket-add.svg"),
    "shopping-basket-check": () => import("!!raw-loader!../../../svg/shopping-basket-check.svg"),
    "shopping-basket-close": () => import("!!raw-loader!../../../svg/shopping-basket-close.svg"),
    "shopping-basket-subtract": () => import("!!raw-loader!../../../svg/shopping-basket-subtract.svg"),
    "shopping_cart_add": () => import("!!raw-loader!../../../svg/shopping_cart_add.svg"),
    "shopping_cart_delete": () => import("!!raw-loader!../../../svg/shopping_cart_delete.svg"),
    "shopping_cart_ok": () => import("!!raw-loader!../../../svg/shopping_cart_ok.svg"),
    "shopping_cart_remove": () => import("!!raw-loader!../../../svg/shopping_cart_remove.svg"),
    "shopping_cart": () => import("!!raw-loader!../../../svg/shopping_cart.svg"),
    "shop": () => import("!!raw-loader!../../../svg/shop.svg"),
    "shuffle": () => import("!!raw-loader!../../../svg/shuffle.svg"),
    "sign-elevator": () => import("!!raw-loader!../../../svg/sign-elevator.svg"),
    "sign-toilet": () => import("!!raw-loader!../../../svg/sign-toilet.svg"),
    "skip_next": () => import("!!raw-loader!../../../svg/skip_next.svg"),
    "skip_previous": () => import("!!raw-loader!../../../svg/skip_previous.svg"),
    "skype": () => import("!!raw-loader!../../../svg/skype.svg"),
    "slideshow": () => import("!!raw-loader!../../../svg/slideshow.svg"),
    "smart-watch-circle-navigation": () => import("!!raw-loader!../../../svg/smart-watch-circle-navigation.svg"),
    "smiley-dolar": () => import("!!raw-loader!../../../svg/smiley-dolar.svg"),
    "smiley-poker-face": () => import("!!raw-loader!../../../svg/smiley-poker-face.svg"),
    "smiley-shy-1": () => import("!!raw-loader!../../../svg/smiley-shy-1.svg"),
    "smiley-smile-2": () => import("!!raw-loader!../../../svg/smiley-smile-2.svg"),
    "smiley-wink": () => import("!!raw-loader!../../../svg/smiley-wink.svg"),
    "smiley-worry": () => import("!!raw-loader!../../../svg/smiley-worry.svg"),
    "soll_und_haben": () => import("!!raw-loader!../../../svg/soll_und_haben.svg"),
    "spa-bamboo": () => import("!!raw-loader!../../../svg/spa-bamboo.svg"),
    "spa-lotion": () => import("!!raw-loader!../../../svg/spa-lotion.svg"),
    "spa-lotus-flower": () => import("!!raw-loader!../../../svg/spa-lotus-flower.svg"),
    "speaker-volume-decrease": () => import("!!raw-loader!../../../svg/speaker-volume-decrease.svg"),
    "speaker-volume-high": () => import("!!raw-loader!../../../svg/speaker-volume-high.svg"),
    "speaker-volume-increase": () => import("!!raw-loader!../../../svg/speaker-volume-increase.svg"),
    "speaker-volume-medium": () => import("!!raw-loader!../../../svg/speaker-volume-medium.svg"),
    "speaker-volume-off": () => import("!!raw-loader!../../../svg/speaker-volume-off.svg"),
    "speech_1": () => import("!!raw-loader!../../../svg/speech_1.svg"),
    "speech_2": () => import("!!raw-loader!../../../svg/speech_2.svg"),
    "speech_3": () => import("!!raw-loader!../../../svg/speech_3.svg"),
    "speech_4": () => import("!!raw-loader!../../../svg/speech_4.svg"),
    "speech_bubble_1": () => import("!!raw-loader!../../../svg/speech_bubble_1.svg"),
    "speech_bubble_2": () => import("!!raw-loader!../../../svg/speech_bubble_2.svg"),
    "speech_bubbles": () => import("!!raw-loader!../../../svg/speech_bubbles.svg"),
    "sport-basketball": () => import("!!raw-loader!../../../svg/sport-basketball.svg"),
    "sport-bowling": () => import("!!raw-loader!../../../svg/sport-bowling.svg"),
    "sport-dumbbell-1": () => import("!!raw-loader!../../../svg/sport-dumbbell-1.svg"),
    "sport-football-field": () => import("!!raw-loader!../../../svg/sport-football-field.svg"),
    "sport-takraw": () => import("!!raw-loader!../../../svg/sport-takraw.svg"),
    "spotify": () => import("!!raw-loader!../../../svg/spotify.svg"),
    "spray-bottle": () => import("!!raw-loader!../../../svg/spray-bottle.svg"),
    "square_add": () => import("!!raw-loader!../../../svg/square_add.svg"),
    "square_dashed_delete": () => import("!!raw-loader!../../../svg/square_dashed_delete.svg"),
    "square_dashed_ok": () => import("!!raw-loader!../../../svg/square_dashed_ok.svg"),
    "square_dashed": () => import("!!raw-loader!../../../svg/square_dashed.svg"),
    "square_delete": () => import("!!raw-loader!../../../svg/square_delete.svg"),
    "square_down": () => import("!!raw-loader!../../../svg/square_down.svg"),
    "square_left": () => import("!!raw-loader!../../../svg/square_left.svg"),
    "square_minus": () => import("!!raw-loader!../../../svg/square_minus.svg"),
    "square_ok": () => import("!!raw-loader!../../../svg/square_ok.svg"),
    "square_plus_minus": () => import("!!raw-loader!../../../svg/square_plus_minus.svg"),
    "square_plus": () => import("!!raw-loader!../../../svg/square_plus.svg"),
    "square_remove": () => import("!!raw-loader!../../../svg/square_remove.svg"),
    "square_right": () => import("!!raw-loader!../../../svg/square_right.svg"),
    "square": () => import("!!raw-loader!../../../svg/square.svg"),
    "square_unlink": () => import("!!raw-loader!../../../svg/square_unlink.svg"),
    "square_up": () => import("!!raw-loader!../../../svg/square_up.svg"),
    "star-constellation": () => import("!!raw-loader!../../../svg/star-constellation.svg"),
    "star": () => import("!!raw-loader!../../../svg/star.svg"),
    "stop": () => import("!!raw-loader!../../../svg/stop.svg"),
    "subtract-circle-1": () => import("!!raw-loader!../../../svg/subtract-circle-1.svg"),
    "suitcase": () => import("!!raw-loader!../../../svg/suitcase.svg"),
    "sunny": () => import("!!raw-loader!../../../svg/sunny.svg"),
    "switch-left": () => import("!!raw-loader!../../../svg/switch-left.svg"),
    "switch-right": () => import("!!raw-loader!../../../svg/switch-right.svg"),
    "synchronize-1": () => import("!!raw-loader!../../../svg/synchronize-1.svg"),
    "synchronize-2": () => import("!!raw-loader!../../../svg/synchronize-2.svg"),
    "tags": () => import("!!raw-loader!../../../svg/tags.svg"),
    "tag": () => import("!!raw-loader!../../../svg/tag.svg"),
    "take_over": () => import("!!raw-loader!../../../svg/take_over.svg"),
    "tank": () => import("!!raw-loader!../../../svg/tank.svg"),
    "target": () => import("!!raw-loader!../../../svg/target.svg"),
    "telephone-1": () => import("!!raw-loader!../../../svg/telephone-1.svg"),
    "temple-2": () => import("!!raw-loader!../../../svg/temple-2.svg"),
    "terminal_browser": () => import("!!raw-loader!../../../svg/terminal_browser.svg"),
    "terminal": () => import("!!raw-loader!../../../svg/terminal.svg"),
    "text_center": () => import("!!raw-loader!../../../svg/text_center.svg"),
    "text_justify": () => import("!!raw-loader!../../../svg/text_justify.svg"),
    "text_left": () => import("!!raw-loader!../../../svg/text_left.svg"),
    "text_right": () => import("!!raw-loader!../../../svg/text_right.svg"),
    "tick": () => import("!!raw-loader!../../../svg/tick.svg"),
    "timer": () => import("!!raw-loader!../../../svg/timer.svg"),
    "time": () => import("!!raw-loader!../../../svg/time.svg"),
    "toilet-roll": () => import("!!raw-loader!../../../svg/toilet-roll.svg"),
    "tools-measuring-tape": () => import("!!raw-loader!../../../svg/tools-measuring-tape.svg"),
    "tools": () => import("!!raw-loader!../../../svg/tools.svg"),
    "tram": () => import("!!raw-loader!../../../svg/tram.svg"),
    "travel-bag-1": () => import("!!raw-loader!../../../svg/travel-bag-1.svg"),
    "travel-beach": () => import("!!raw-loader!../../../svg/travel-beach.svg"),
    "travel-camping": () => import("!!raw-loader!../../../svg/travel-camping.svg"),
    "travel-flippers": () => import("!!raw-loader!../../../svg/travel-flippers.svg"),
    "travel-globe": () => import("!!raw-loader!../../../svg/travel-globe.svg"),
    "treasure-chest-open": () => import("!!raw-loader!../../../svg/treasure-chest-open.svg"),
    "trolley-box-service": () => import("!!raw-loader!../../../svg/trolley-box-service.svg"),
    "tumblr": () => import("!!raw-loader!../../../svg/tumblr.svg"),
    "twitter": () => import("!!raw-loader!../../../svg/twitter.svg"),
    "umbrella-open": () => import("!!raw-loader!../../../svg/umbrella-open.svg"),
    "undershirt": () => import("!!raw-loader!../../../svg/undershirt.svg"),
    "underwear": () => import("!!raw-loader!../../../svg/underwear.svg"),
    "unlinked": () => import("!!raw-loader!../../../svg/unlinked.svg"),
    "unlink": () => import("!!raw-loader!../../../svg/unlink.svg"),
    "unlock": () => import("!!raw-loader!../../../svg/unlock.svg"),
    "upload-computer": () => import("!!raw-loader!../../../svg/upload-computer.svg"),
    "upload": () => import("!!raw-loader!../../../svg/upload.svg"),
    "user-add": () => import("!!raw-loader!../../../svg/user-add.svg"),
    "user_add": () => import("!!raw-loader!../../../svg/user_add.svg"),
    "user-chat-1": () => import("!!raw-loader!../../../svg/user-chat-1.svg"),
    "user-check": () => import("!!raw-loader!../../../svg/user-check.svg"),
    "user_circle": () => import("!!raw-loader!../../../svg/user_circle.svg"),
    "user_delete": () => import("!!raw-loader!../../../svg/user_delete.svg"),
    "user-female": () => import("!!raw-loader!../../../svg/user-female.svg"),
    "user-headphone": () => import("!!raw-loader!../../../svg/user-headphone.svg"),
    "user-heart": () => import("!!raw-loader!../../../svg/user-heart.svg"),
    "user-magnify": () => import("!!raw-loader!../../../svg/user-magnify.svg"),
    "user-male": () => import("!!raw-loader!../../../svg/user-male.svg"),
    "user_ok": () => import("!!raw-loader!../../../svg/user_ok.svg"),
    "user-plus1": () => import("!!raw-loader!../../../svg/user-plus1.svg"),
    "user_remove": () => import("!!raw-loader!../../../svg/user_remove.svg"),
    "users_following": () => import("!!raw-loader!../../../svg/users_following.svg"),
    "user_square": () => import("!!raw-loader!../../../svg/user_square.svg"),
    "users": () => import("!!raw-loader!../../../svg/users.svg"),
    "user-subtract": () => import("!!raw-loader!../../../svg/user-subtract.svg"),
    "user": () => import("!!raw-loader!../../../svg/user.svg"),
    "vector-circle": () => import("!!raw-loader!../../../svg/vector-circle.svg"),
    "vector-line": () => import("!!raw-loader!../../../svg/vector-line.svg"),
    "vector-square-1": () => import("!!raw-loader!../../../svg/vector-square-1.svg"),
    "vector-triangle": () => import("!!raw-loader!../../../svg/vector-triangle.svg"),
    "video-call-1": () => import("!!raw-loader!../../../svg/video-call-1.svg"),
    "video-call-mobile-phone": () => import("!!raw-loader!../../../svg/video-call-mobile-phone.svg"),
    "video-camera-3": () => import("!!raw-loader!../../../svg/video-camera-3.svg"),
    "video-clip-3": () => import("!!raw-loader!../../../svg/video-clip-3.svg"),
    "video-clip-4": () => import("!!raw-loader!../../../svg/video-clip-4.svg"),
    "video-control-eject": () => import("!!raw-loader!../../../svg/video-control-eject.svg"),
    "video-control-fast-forward": () => import("!!raw-loader!../../../svg/video-control-fast-forward.svg"),
    "video-control-next": () => import("!!raw-loader!../../../svg/video-control-next.svg"),
    "video-control-pause": () => import("!!raw-loader!../../../svg/video-control-pause.svg"),
    "video-control-play": () => import("!!raw-loader!../../../svg/video-control-play.svg"),
    "video-control-previous": () => import("!!raw-loader!../../../svg/video-control-previous.svg"),
    "video-control-record": () => import("!!raw-loader!../../../svg/video-control-record.svg"),
    "video-control-rewind": () => import("!!raw-loader!../../../svg/video-control-rewind.svg"),
    "video-control-stop": () => import("!!raw-loader!../../../svg/video-control-stop.svg"),
    "video-games-gameboy": () => import("!!raw-loader!../../../svg/video-games-gameboy.svg"),
    "video-games-pacman-ghost": () => import("!!raw-loader!../../../svg/video-games-pacman-ghost.svg"),
    "video-games-pacman": () => import("!!raw-loader!../../../svg/video-games-pacman.svg"),
    "view_off": () => import("!!raw-loader!../../../svg/view_off.svg"),
    "view": () => import("!!raw-loader!../../../svg/view.svg"),
    "vimeo": () => import("!!raw-loader!../../../svg/vimeo.svg"),
    "voicemail": () => import("!!raw-loader!../../../svg/voicemail.svg"),
    "volcanoe": () => import("!!raw-loader!../../../svg/volcanoe.svg"),
    "volume_1": () => import("!!raw-loader!../../../svg/volume_1.svg"),
    "volume_2": () => import("!!raw-loader!../../../svg/volume_2.svg"),
    "volume_3": () => import("!!raw-loader!../../../svg/volume_3.svg"),
    "volume_down": () => import("!!raw-loader!../../../svg/volume_down.svg"),
    "volume_mute": () => import("!!raw-loader!../../../svg/volume_mute.svg"),
    "volume_off": () => import("!!raw-loader!../../../svg/volume_off.svg"),
    "volume_up": () => import("!!raw-loader!../../../svg/volume_up.svg"),
    "vote-heart-circle-1": () => import("!!raw-loader!../../../svg/vote-heart-circle-1.svg"),
    "vote-plus-one": () => import("!!raw-loader!../../../svg/vote-plus-one.svg"),
    "vote-thumbs-down": () => import("!!raw-loader!../../../svg/vote-thumbs-down.svg"),
    "vote-thumbs-up": () => import("!!raw-loader!../../../svg/vote-thumbs-up.svg"),
    "wallet": () => import("!!raw-loader!../../../svg/wallet.svg"),
    "warehouse-box": () => import("!!raw-loader!../../../svg/warehouse-box.svg"),
    "warning": () => import("!!raw-loader!../../../svg/warning.svg"),
    "water-droplet": () => import("!!raw-loader!../../../svg/water-droplet.svg"),
    "water-tap": () => import("!!raw-loader!../../../svg/water-tap.svg"),
    "water-tower": () => import("!!raw-loader!../../../svg/water-tower.svg"),
    "wifi_1": () => import("!!raw-loader!../../../svg/wifi_1.svg"),
    "wifi_2": () => import("!!raw-loader!../../../svg/wifi_2.svg"),
    "wifi_3": () => import("!!raw-loader!../../../svg/wifi_3.svg"),
    "wind-flag": () => import("!!raw-loader!../../../svg/wind-flag.svg"),
    "window-programming": () => import("!!raw-loader!../../../svg/window-programming.svg"),
    "windows": () => import("!!raw-loader!../../../svg/windows.svg"),
    "window": () => import("!!raw-loader!../../../svg/window.svg"),
    "youtube": () => import("!!raw-loader!../../../svg/youtube.svg"),
    "zoom_in": () => import("!!raw-loader!../../../svg/zoom_in.svg"),
    "zoom_out": () => import("!!raw-loader!../../../svg/zoom_out.svg")
};
