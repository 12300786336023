import {types} from "mobx-state-tree";

export const InlineSvgStore = types
    .model("InlineSvgStore", {
        svg: types.string
    })
    .views(self => {
        return {
            get markup() {
                return {__html: self.svg};
            }
        };
    })
    .actions(self => ({
            setSvg: (value: string) => {
                self.svg = value;
            }
        })
    );
