import * as React from "react";
import * as ReactDOM from "react-dom";

const modalRoot = document.getElementById("modal-root");

interface IProps {
    children: React.ReactNode;
}

// Let's create a Modal component that is an abstraction around
// the portal API.
export class Modal extends React.Component<IProps> {
    private readonly el: HTMLElement;

    constructor(props: IProps) {
        super(props);
        // Create a div that we'll render the modal into. Because each
        // Modal component has its own element, we can render multiple
        // modal components into the modal container.
        this.el = document.createElement("div");
    }

    public componentDidMount() {
        // Append the element into the DOM on mount. We'll render
        // into the modal container element (see the HTML tab).
        if (modalRoot) {
            modalRoot.appendChild(this.el);
        }
    }

    public componentWillUnmount() {
        // Remove the element from the DOM when we unmount
        if (modalRoot) {
            modalRoot.removeChild(this.el);
        }
    }

    public render() {
        // Use a portal to render the children into the element
        return ReactDOM.createPortal(
            // Any valid React child: JSX, strings, arrays, etc.
            this.props.children,
            // A DOM element
            this.el
        );
    }
}
