import {Rnd} from "react-rnd/lib";
// Rnd Fix für Dialoge in Modal Component.
// Weil Draggable sonst die doppelte X und Y Werte bekommen hat.
// Weil Modal als Parent angenommen wird, aber zum Zeitpunkt von
// ComponentDidMount noch nicht im DOM ist um Werte abzufragen
export class RndFixed extends Rnd {
    // tslint:disable-next-line:no-empty
    public componentDidMount() {
    }
}
