import * as selectbg from "!!raw-loader!../../svg/selectbg.svg";
import {autorun} from "mobx";
import {applySnapshot, Instance, types} from "mobx-state-tree";
import * as Tinycolor from "tinycolor2";
import {ApiCaller, IApiCallerResponse} from "../class/class.ApiCaller";
import {debounce} from "../functions/debounce";
const selectbgString = selectbg.default;

const ColorStore = types.model({
    background: types.string
}).views((self) => {
    const border = function(color: Tinycolor.Instance) {
        if (color.isDark()) {
            return color.lighten(15).toRgbString();
        } else {
            return color.darken(15).toRgbString();
        }
    };
    const text = function(backColor: Tinycolor.Instance) {
        const colors = [
            {
                color: "white",
                // WCAG 2.0 recommended ratio: 3.0
                alpha: 1.0,
                ratio: 1.55
            },
            {
                color: "black",
                // WCAG 2.0 recommended ratio: 4.5
                // old alpha: 0.87,
                alpha: 0.65,
                ratio: 4.5
            }
        ];

        let bestDiff = 0;
        let bestColor = backColor.complement();
        for (const textColor of colors) {
            const tc = Tinycolor(textColor.color);
            tc.setAlpha(textColor.alpha);

            const contrast = Tinycolor.readability(backColor, overlayOn(backColor, tc));

            if (contrast >= bestDiff) {
                bestDiff = contrast;
                bestColor = tc;
            }
            if (contrast >= textColor.ratio) {
                break;
            }
        }
        return bestColor.toRgbString();
    };
    const shadow = function(color: Tinycolor.Instance) {
        return color.setAlpha(0.4).toRgbString();
    };
    const focusBackground = function(): string {
        const color = Tinycolor(self.background);
        const rgb = color.toRgb();
        const isDark = color.isDark();
        if (rgb.r + rgb.g === rgb.r + rgb.b) {
            if (isDark) {
                return color.lighten(10).toRgbString();
            } else {
                return color.darken(10).toRgbString();
            }
        } else {
            if (isDark) {
                return color.lighten(10).saturate(10).toRgbString();
            } else {
                return color.darken(10).desaturate(10).toRgbString();
            }
        }
    };
    const overlayOn = function(backColor: Tinycolor.Instance, textColor: Tinycolor.Instance) {
        const textRgba = textColor.toRgb();
        const backRgba = backColor.toRgb();

        if (!(textRgba.a >= 1)) {
            textRgba.r = textRgba.r * textRgba.a + backRgba.r * backRgba.a * (1 - textRgba.a);
            textRgba.g = textRgba.g * textRgba.a + backRgba.g * backRgba.a * (1 - textRgba.a);
            textRgba.b = textRgba.b * textRgba.a + backRgba.b * backRgba.a * (1 - textRgba.a);
            textRgba.a = textRgba.a + backRgba.a * (1 - textRgba.a);
        }

        return new Tinycolor(textRgba);
    };
    const selectbg = function(bgColor: Tinycolor.Instance, fgColor: Tinycolor.Instance) {
        const parser = new DOMParser();
        const xml = parser.parseFromString(selectbgString, "image/svg+xml");
        const bg = xml.getElementById("bg");
        if (bg) {
            bg.setAttribute("fill", "#" + bgColor.toHex());
        }
        const fg = xml.getElementById("arrow");
        if (fg) {
            fg.setAttribute("fill", "#" + fgColor.toHex());
        }
        return new XMLSerializer().serializeToString(xml);
    };

    return {
        get border(): string {
            return border(Tinycolor(self.background));
        },
        get shadow(): string {
            return shadow(Tinycolor(self.background));
        },
        get text(): string {
            return text(Tinycolor(self.background));
        },
        get icon(): string {
            const color = Tinycolor(text(Tinycolor(self.background)));
            if (color.isDark()) {
                return color.setAlpha(0.54).toRgbString();
            } else {
                return color.setAlpha(0.70).toRgbString();
            }
        },
        get focusBackground(): string {
            return focusBackground();
        },
        get focusBorder(): string {
            return border(Tinycolor(focusBackground()));
        },
        get focusShadow(): string {
            return shadow(Tinycolor(focusBackground()));
        },
        get focusText(): string {
            return text(Tinycolor(focusBackground()));
        },
        get focusIcon(): string {
            const color = Tinycolor(text(Tinycolor(focusBackground())));
            if (color.isDark()) {
                return color.setAlpha(0.87).toRgbString();
            } else {
                return color.setAlpha(1).toRgbString();
            }
        },
        get hoverBackground(): string {
            return focusBackground();
        },
        get hoverBorder(): string {
            return border(Tinycolor(focusBackground()));
        },
        get hoverShadow(): string {
            return shadow(Tinycolor(focusBackground()));
        },
        get hoverText(): string {
            return text(Tinycolor(focusBackground()));
        },
        get hoverIcon(): string {
            const color = Tinycolor(text(Tinycolor(focusBackground())));
            if (color.isDark()) {
                return color.setAlpha(0.87).toRgbString();
            } else {
                return color.setAlpha(1).toRgbString();
            }
        },
        get inactiveIcon(): string {
            const color = Tinycolor(text(Tinycolor(self.background)));
            if (color.isDark()) {
                return color.setAlpha(0.38).toRgbString();
            } else {
                return color.setAlpha(0.5).toRgbString();
            }
        },
        get selectBg(): string {
            return selectbg(Tinycolor(self.background), Tinycolor(text(Tinycolor(self.background))));
        },
        get selectBgAlt(): string {
            return selectbg(Tinycolor(text(Tinycolor(self.background))), Tinycolor(self.background));
        }
    };
}).actions(self => ({
    setColor: (color: string) => {
        self.background = color;
    }
}));

export const ThemeStore = types.model({
    blocks: types.map(ColorStore),
    scrollbarWidth: types.optional(types.number, () => {
        const scrollDiv = document.createElement("div");
        scrollDiv.style.width = "100px";
        scrollDiv.style.height = "100px";
        scrollDiv.style.overflow = "scroll";
        scrollDiv.style.position = "absolute";
        scrollDiv.style.top = "-999px";
        document.body.appendChild(scrollDiv);
        const width = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
        return width;
    })
});

export const Theme = ThemeStore.create();

const style = document.createElement("style");
style.setAttribute("data-themeEngine", "javascript styles");
document.head.appendChild(style);
const styleSheet = style.sheet as CSSStyleSheet;
const rootRule = styleSheet.cssRules[
    styleSheet.insertRule(":root {}", 0)
    ] as CSSStyleRule;

const debounced = debounce(() => {
    const set = function(variable: string, value: string) {
        if (rootRule) {
            rootRule.style.setProperty(variable, value.toString());
        }
    };

    Theme.blocks.forEach((value: Instance<typeof ColorStore>, key: string) => {
        autorun(() => {
            set("--" + key + "-background", value.background);
            set("--" + key + "-border", value.border);
            set("--" + key + "-shadow", value.shadow);
            set("--" + key + "-text", value.text);
            set("--" + key + "-icon", value.icon);

            set("--" + key + "-focus-background", value.focusBackground);
            set("--" + key + "-focus-border", value.focusBorder);
            set("--" + key + "-focus-shadow", value.focusShadow);
            set("--" + key + "-focus-text", value.focusText);
            set("--" + key + "-focus-icon", value.focusIcon);

            set("--" + key + "-hover-background", value.hoverBackground);
            set("--" + key + "-hover-border", value.hoverBorder);
            set("--" + key + "-hover-shadow", value.hoverShadow);
            set("--" + key + "-hover-text", value.hoverText);
            set("--" + key + "-hover-icon", value.hoverIcon);
        });
    });
    const primary = Theme.blocks.get("primary");
    if (primary) {
        autorun(() => {
            set("--select", `url('data:image/svg+xml;utf8,${encodeURIComponent(primary.selectBg)}')`);
            set("--selectAlt", `url('data:image/svg+xml;utf8,${encodeURIComponent(primary.selectBgAlt)}')`);
        });
    }
    set("--scrollbar-width", Theme.scrollbarWidth + "px");
    document.body.style.visibility = "visible";
}, 100);

Theme.blocks.observe(debounced);

export const loadColors = () => {
    const apicaller = new ApiCaller();
    apicaller.call("config/colors").then((response: IApiCallerResponse<{ [block: string]: string }>) => {
        const data = Object.entries(response.response).reduce<{ [key: string]: { background: string } }>((acc, cur) => {
            const [block, color] = cur;
            acc[block] = {background: color};
            return acc;
        }, {});

        applySnapshot(Theme, {blocks: data});
    });
};
loadColors();

export const saveColors = () => {
    const apicaller = new ApiCaller();
    const colors: { [key: string]: string } = {};
    Theme.blocks.forEach((value, key) => {
        colors![key] = value.background;
    });

    apicaller.call("config/colors", "PUT", undefined, colors).then();
};

export const resetColors = () => {
    const apicaller = new ApiCaller();
    apicaller.call("config/colors", "PUT", undefined, {}).then();
};

export const setColor = debounce((key: string, color: string) => {
    const block = Theme.blocks.get(key);
    if (block) {
        block.setColor(color);
    }
});
