import * as React from "react";
import {ApiCaller} from "../../class/class.ApiCaller";
import {getProjectOrigin} from "../../functions/getProjectOrigin";
import {StandardSelect} from "../Standard/StandardSelect";
import * as styles from "./Login.less";
import {Register} from "./Register";
import {StandardInput} from "../Standard/StandardInput";
import "../../GlobalStores/Theme";

interface IState {
    isdemo: boolean;
    lang: string;
    message: string;
    password: string;
    registerOpen: boolean;
    standardprofile: string;
    status?: string;
    themeLoaded: boolean;
    username: string;
}

export class Login extends React.Component<{}, IState> {
    private focusInput: React.RefObject<HTMLInputElement> = React.createRef();
    constructor(props: {}) {
        super(props);
        this.state = {
            isdemo: false,
            lang: "german",
            message: " ",
            password: "",
            registerOpen: false,
            standardprofile: "root",
            themeLoaded: false,
            username: ""
        };
    }

    public async componentDidMount() {
        const apicaller = new ApiCaller();
        const response = (await apicaller.call("config")).response;
        this.setState({isdemo: response.isdemo === "1"});
        this.setState({message: response.Company_Name});
        this.setState({themeLoaded: true});
    }

    public componentDidUpdate(prevProps: {}, prevState: IState) {
        // if (this.focusInput && this.focusInput.current && this.state.username !== prevState.username) {
        //     this.focusInput.current.focus();
        // }
      }

    public render() {
        // return <div>test</div>;
        if (!this.state.themeLoaded) {
            return false;
        }

        let style = styles.notifier;
        if (this.state.status === "showErrorMessage" || this.state.status === "error") {
            style = style + " " + styles.notifierError;
        } else if (this.state.status === "loginSuccess" || this.state.status === "success") {
            style = style + " " + styles.notifierSuccess;
        }

        const notifier = (
            <div id="notifier" className={style}>
                <span>{this.state.message}</span>
            </div>
        );

        const languages = [
            {
                label: "Deutsch",
                value: "german"
            },
            {
                label: "Englisch",
                value: "english"
            }
        ];

        const standardprofile = [
            {
                label: "Standard",
                value: "root"
            },
            {
                label: "Notebook",
                value: "notebook"
            },
            {
                label: "Tablet",
                value: "tablet"
            },
            {
                label: "Smartphone",
                value: "smartphone"
            }
        ];

        return (
            <div>
                {/*<form method="POST" onSubmit={this.handleSubmit}>*/}
                    <div className={styles.login}>
                        {notifier}
                        <div className={styles.container}>
                            <div className={styles.row}>
                                <div className={styles.loginColumn + " " + styles.loginLogoArea}>
                                    <div className={styles.loginLogo} />
                                    <h1>Anmeldung</h1>
                                    <StandardSelect
                                        style={{gridArea: "profile"}}
                                        options={standardprofile}
                                        value={this.state.standardprofile}
                                        onChange={this.handleChangeStandardProfile}
                                        name="standard_profile"
                                    />
                                    <StandardSelect
                                        style={{gridArea: "lang"}}
                                        options={languages}
                                        value={this.state.lang}
                                        onChange={this.handleChangeLanguage}
                                        name="lang"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.container + " " + styles.loginArea}>
                            <div className={styles.row}>
                                <noscript>
                                    <div className={styles.noscriptmsg}>
                                        Damit Sie PHPW nutzen können, muss in Ihrem Browser
                                        JavaScript aktiviert sein.
                                    </div>
                                </noscript>

                                <div className={styles.loginColumn + " " + styles.loginFields}>
                                    <div className={styles.loginUsername}>
                                        <span className={styles.loginLabel}>Benutzername:</span>
                                        <StandardInput
                                            name="username"
                                            // ref={this.focusInput}
                                            autoFocus={true}
                                            onChange={this.handleChangeUser}
                                            onEnter={this.handleSubmit}
                                            value={this.state.username}
                                        />
                                    </div>
                                    <div className={styles.loginPassword}>
                                        <span className={styles.loginLabel}>Passwort:</span>
                                        <StandardInput
                                            type="password"
                                            name="password"
                                            onChange={this.handleChangePassword}
                                            onEnter={this.handleSubmit}
                                            value={this.state.password}
                                        />
                                    </div>
                                    <div className={styles.loginButtons}>
                                        <span className={styles.loginLabel} />
                                        <span className={styles.loginButton}>
                                            <button onClick={this.handleSubmit} className={styles.loginBtn}>Login</button>

                                            {this.state.isdemo && (
                                                <button
                                                    className={styles.signupMaskBtn}
                                                    onClick={this.toggleRegister}
                                                >
                                                    Zugang anfragen
                                                </button>
                                            )}
                                            {this.state.registerOpen && (
                                                <Register
                                                    handleNotification={this.handleNotification}
                                                    close={this.toggleRegister}
                                                />
                                            )}
                                        </span>
                                    </div>
                                    <br />
                                    <br />
                                    <div className={styles.loginButtons}>
                                        <span className={styles.loginLabel} />
                                        <span className={styles.loginButton} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/*</form>*/}
            </div>
        );
    }

    private handleChangeUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({username: event.target.value});
    }

    private handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({password: event.target.value});
    }

    private handleChangeStandardProfile = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({standardprofile: event.target.value});
    }

    private handleChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({lang: event.target.value});
    }

    private toggleRegister = (event: React.MouseEvent<HTMLDivElement>) => {
        this.setState((prevState) => ({
            registerOpen: !prevState.registerOpen
        }));
    }

    private handleNotification = (message: string, status = undefined) => {
        this.setState({message: message});
        if (status) {
            this.setState({status: status});
        }
    }

    private handleSubmit = (event: React.FormEvent<HTMLInputElement | HTMLDivElement>) => {
        const url = getProjectOrigin("/modules/redesign/checkLogin.php");

        const formData = new FormData();
        formData.append("username", this.state.username);
        formData.append("password", this.state.password);
        formData.append("lang", this.state.lang);
        formData.append("standard_profile", this.state.standardprofile);

        const xhttp = new XMLHttpRequest();
        xhttp.open("POST", url, false);
        xhttp.onload = () => {
            const data = JSON.parse(xhttp.response);
            this.setState({message: data.message});
            this.setState({status: data.action});
            if (data.action === "loginSuccess") {
                window.location.href = getProjectOrigin("/");
            }
        };
        xhttp.send(formData);
        event.preventDefault();
    }

}
