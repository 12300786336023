export class FormDataCreator {
    constructor(objectToSend) {
        this.objectToSend = objectToSend;
        this.objectShadow = {};
        this.objectSpec = {};
    }

    objectToSend = undefined;
    objectShadow = undefined;
    objectSpec = undefined;

    preparedObjectData(objectData, shadowedObject, shadowObjectString) {
        if (!shadowedObject) {
            shadowedObject = this.objectShadow;
        }
        if (!objectData) {
            objectData = this.objectToSend;
        }
        if (!shadowObjectString) {
            shadowObjectString = "";
        }

        const objectKeys = Object.keys(objectData);
        const objectKeysLength = objectKeys.length;

        for (let i = 0; i < objectKeysLength; i++) {
            const keyName = objectKeys[i];
            const item = objectData[keyName];

            const shadowObjectStringNext = this.getShadowStringNext(shadowObjectString, keyName);

            switch (Object.prototype.toString.call(item)) {
                case "[object Object]":
                case "[object Array]":
                    const tmp = {};
                    shadowedObject[keyName] = tmp;
                    this.preparedObjectData(item, tmp, shadowObjectStringNext);
                    break;
                case "[object File]":
                case "[object Blob]":
                    this.objectSpec[shadowObjectStringNext] = item;
                    break;
                default:
                    shadowedObject[keyName] = item;
            }
        }
    }

    getShadowStringNext(shadowObjectString, keyName) {
        let shadowObjectStringNext = "";

        if (shadowObjectString !== null && shadowObjectString.length > 0) {
            shadowObjectStringNext = shadowObjectString + ("[" + keyName + "]");
        } else {
            shadowObjectStringNext = keyName;
        }

        return shadowObjectStringNext;
    }

    getFormData() {
        const rawString = this.param().replace(/\+/g, " ");
        const formData = new FormData();
        const rawKeyValArr = rawString.split("&");
        const length = rawKeyValArr.length;
        for (let i = 0; i < length; i++) {
            const keyVal = rawKeyValArr[i].split("=");
            formData.append(decodeURIComponent(keyVal[0]), decodeURIComponent(keyVal[1]));
        }

        const objectKeys = Object.keys(this.objectSpec);
        const objectKeysLength = objectKeys.length;
        for (let i = 0; i < objectKeysLength; i++) {
            const keyName = objectKeys[i];
            formData.append(keyName, this.objectSpec[keyName]);
        }
        return formData;
    }

    rawFormData() {
        const rawString = this.param().replace(/\+/g, " ");
        const rawFormData = rawString.split("&").reduce(function(accu, current) {
            const keyVal = current.split("=");
            accu[decodeURIComponent(keyVal[0])] = decodeURIComponent(keyVal[1]);
            return accu;
        }, {});

        //        console.log(this.objectSpec);
        const objectKeys = Object.keys(this.objectSpec);
        const objectKeysLength = objectKeys.length;
        for (let i = 0; i < objectKeysLength; i++) {
            const keyName = objectKeys[i];
            rawFormData[keyName] = this.objectSpec[keyName];
        }
        return rawFormData;
    }

    param() {
        const that = this;
        const collector = [];
        const itemJoin = function(prop, value) {
            if (typeof value === "undefined" || value === null) {
                return;
            }
            collector.push(`${encodeURIComponent(prop)}=${encodeURIComponent(value)}`);
        };
        const builder = function(objectData, shadowedObject, shadowObjectString) {
            const objectKeys = Object.keys(objectData);
            const objectKeysLength = objectKeys.length;

            for (let i = 0; i < objectKeysLength; i++) {
                const keyName = objectKeys[i];
                const item = objectData[keyName];

                const shadowObjectStringNext = that.getShadowStringNext(
                    shadowObjectString,
                    keyName
                );

                switch (Object.prototype.toString.call(item)) {
                    case "[object Object]":
                    case "[object Array]":
                        const tmp = {};
                        shadowedObject[keyName] = tmp;
                        builder(item, tmp, shadowObjectStringNext);
                        break;
                    case "[object File]":
                    case "[object Blob]":
                        that.objectSpec[shadowObjectStringNext] = item;
                        break;
                    default:
                        itemJoin(shadowObjectStringNext, item);
                }
            }
        };
        // this.preparedObjectData();
        builder(this.objectToSend, this.objectShadow, "");
        return collector.join("&");
    }
}