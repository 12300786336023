import {observer} from "mobx-react";
import * as React from "react";
import * as styles from "./StandardSelect.less";

export interface ISelectOption {
    label: string | React.ReactFragment;
    value: string | number;
    id?: string;
}

interface IProps {
    options: ISelectOption[];
    disabled?: boolean;
    hasPlaceholder?: boolean;
    style?: { [s: string]: string };
    value?: string | number;
    name?: string;
    tabIndex?: number;

    onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

@observer
export class StandardSelect extends React.Component<IProps> {
    public static defaultProps: Partial<IProps> = {
        disabled: false,
        hasPlaceholder: false,
        name: "",
        value: "",
        onChange: () => {
            console.error("No change handler");
        },
        options: [
            {
                label: "NICHTS",
                value: "none"
            }
        ],
        style: {}
    };

    public render() {
        let selectValue = this.props.value + "";
        let selectValueFound = false;
        const options = this.props.options.map((option, index) => {
                if (selectValue === (option.value + "")) {
                    selectValueFound = true;
                }
                return (
                    <option key={index} value={option.value || option.id}>
                        {option.label}
                    </option>
                );
            })
        ;

        if (this.props.hasPlaceholder) {
            if (!selectValueFound) {
                selectValue = "";
            }
            options.unshift(
                <option disabled={true} key={-1} value={""}>
                    Bitte wählen...
                </option>
            );
        }

        return (
            <select
                style={this.props.style}
                className={styles.select}
                value={selectValue}
                name={this.props.name}
                tabIndex={this.props.tabIndex}
                onChange={this.props.onChange}
                disabled={this.props.disabled}
            >
                {options}
            </select>
        );
    }
}
