import {observer} from "mobx-react";
import * as React from "react";
import {InlineSvg} from "../Svg/InlineSvg";
import * as styles from "./StandardCheckbox.less";

interface IProps {
    disabled?: boolean;
    checked: boolean;
    hasPlaceholder?: boolean;
    style?: {};
    className?: string;
    width?: string | number;
    height?: string | number;

    onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

@observer
export class StandardCheckbox extends React.Component<IProps> {
    public static defaultProps: Partial<IProps> = {
        width: "20px",
        height: "20px",
        checked: false,
        onChange: function() {
            console.error("No change handler");
        }
    };
    private ref = React.createRef<HTMLInputElement>();

    public render() {
        const className = [styles.container];
        let disableableData = {};
        if (this.props.disabled) {
            className.push(styles.containerDisabled);
            disableableData = {
                disabled: true,
                checked: this.props.checked,
            };
        } else {
            disableableData = {
                checked: this.props.checked,
                onChange: this.props.onChange,
            };
        }
        if (this.props.className) {
            className.push(this.props.className);
        }

        return (
            <label className={className.join(" ")} style={this.props.style}>
                <InlineSvg
                    width={this.props.width}
                    height={this.props.height}
                    name={this.props.checked ? "checkbox_true" : "checkbox_false"}
                />
                <input
                    {...disableableData}
                    ref={this.ref}
                    className={styles.input}
                    type={"checkbox"}
                />
            </label>
        );
    }
}
