import {ApiCaller} from "./class.ApiCaller";

let config_instance = null;

export class Config {
    data = {};

    constructor() {
        if (!config_instance) {
            const api = new ApiCaller();
            this.data = api.call("config");
            api.call("config/session", "PUT", {
                standard_profile: Config.getInSession("sess_standard_profile")
            });
            config_instance = this;
        }

        return config_instance;
    }

    async get(key = null) {
        const data = (await this.data).response;

        if (key) {
            return data[key];
        } else {
            return data.response;
        }
    }

    static setInSession(key, value) {
        window.localStorage.setItem(key, value);
        const temp = {};
        temp[key] = value;
        const api = new ApiCaller();
        api.call("config/session", "PUT", temp);
    }

    static getInSession(key) {
        // if (window.hasOwnProperty("localStorage") && window.localStorage.hasOwnProperty(key)) {
        if (window.localStorage[key] === "true") return true;
        else if (window.localStorage[key] === "false") return false;
        else {
            return window.localStorage[key];
        }
        // }
        return false;
    }
}
